import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { Breakpoint, CircularProgress, Stack, Typography } from "@mui/material"
import DialogActionButton, {
  TConfirmDialogButton,
} from "./DialogActions/DialogActionButton"
import StackOrForm from "../Divs/StackOrForm"
import SwipeableDrawerTitle from "./SwipeableDrawerTitle"

export interface IConfirmDialogProps {
  open: boolean
  title?: string | React.ReactNode
  children?: React.ReactNode
  onClose: () => void
  labelPrositive?: string
  onPositive?: () => Promise<boolean>
  maxWidth?: false | Breakpoint | undefined
  hasCancel?: boolean | undefined
  isTitle?: boolean
  subtitle?: string
  buttons?: TConfirmDialogButton[] | undefined
  buttonsDisabled?: boolean
  noValidate?: boolean | undefined
  component?: "form"
  hideButtons?: boolean | undefined
  paddingContent?: string | undefined
  testId?: string
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
}

export default function ContentDialog(props: IConfirmDialogProps) {
  const {
    open,
    title,
    children,
    onClose,
    labelPrositive = "OK",
    onPositive,
    maxWidth,
    hasCancel,
    isTitle,
    subtitle,
    buttons,
    buttonsDisabled,
    noValidate,
    component,
    paddingContent,
    hideButtons = false,
    testId,
    onSubmit,
  } = props
  const [loading, setLoading] = React.useState(false)

  const handleYes = async () => {
    setLoading(true)
    try {
      var close = true

      if (onPositive && onPositive !== null) {
        close = await onPositive()
      }

      if (close) {
        onClose()
      }
    } finally {
      setLoading(false)
    }
  }

  const Loading = () => {
    return (
      <Stack
        direction="column"
        sx={{ p: 12 }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth || "sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StackOrForm
        component={component}
        noValidate={noValidate}
        onSubmit={onSubmit}
      >
        {(isTitle === undefined || isTitle === true) && (
          <DialogTitle>
            <SwipeableDrawerTitle
              setOpen={(value) => onClose()}
              title={title ?? process.env.REACT_APP_COPYRIGHT ?? "Mensagem"}
              subtitle={
                subtitle ? (
                  <Typography variant="caption" sx={{ flexGrow: 1 }}>
                    {subtitle}
                  </Typography>
                ) : undefined
              }
            />
          </DialogTitle>
        )}
        <DialogContent sx={{ p: paddingContent }}>
          {loading ? <Loading /> : children}
        </DialogContent>
        {buttons && (
          <DialogActions>
            {buttons.map((button) => (
              <DialogActionButton button={button} disabled={buttonsDisabled} />
            ))}
          </DialogActions>
        )}
        {!buttons && !hideButtons && (
          <DialogActions>
            {hasCancel && (
              <DialogActionButton
                button={{
                  title: "Cancelar",
                  type: "negative",
                  onClick: onClose,
                }}
                disabled={buttonsDisabled}
              />
            )}
            <DialogActionButton
              button={{
                title: labelPrositive,
                type: "positive",
                onClick: handleYes,
              }}
              disabled={buttonsDisabled}
              testId={testId || "contentDialogButtonPositive"}
            />
          </DialogActions>
        )}
      </StackOrForm>
    </Dialog>
  )
}
