import { numberOnly } from "../../masks/masks"
import { TMetaPurchase } from "../types/Meta"
import { TSaleStorage } from "../types/Sale"
import TagFunctions, { ITagsProp } from "./TagFunctions"
import {
  TPaymentMethodEnum,
  TProductPixel,
  TProductPixelType,
} from "../types/Product"
import Cookies from "js-cookie"
import ProductController from "../controllers/ProductController"
import Convertions from "./convertions"
import CheckoutFunctions from "./CheckoutFunctions"
import { addDays } from "./dateTime"
import HostFunctions from "./HostFunctions"

const getFbpAndFbc = () => {
  let fbp: string | undefined = Cookies.get("_apfbp")
  let fbc: string | undefined = Cookies.get("_apfbc")
  let fbclid: string | undefined = Cookies.get("_apfbclid")

  if (!fbp || fbp === "") {
    fbp = Cookies.get("_fbp")
  }
  if (!fbc || fbc === "") {
    fbc = Cookies.get("_fbc")
  }

  if (!fbc || fbc === "") {
    if (fbclid && fbclid !== "") {
      const current_timestamp = Math.floor(
        new Date().getTime() / 1000
      ).toString()
      /* 
                https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/ 
            */
      fbc = `fb.1.${current_timestamp}.${fbclid}`
      Cookies.set("_apfbc", fbc, { expires: addDays(365) })
    }
  }

  return { fbp, fbc, fbclid }
}

const addTagInitiateCheckout = (
  eventId: string,
  productPriceCode: string,
  productCode: string,
  productName: string,
  productValue: number,
  productPixels?: TProductPixel[],
  customerEmail?: string,
  customerName?: string,
  customerPhone?: string,
  customerDocument?: string,
  customerCity?: string,
  customerState?: string,
  customerZipCode?: string,
  customerCountry?: string
): ITagsProp | null => {
  if (!productPixels || productPixels.length <= 0) {
    return null
  }

  const pixels = productPixels.filter(
    (pp) =>
      pp.type === TProductPixelType.Facebook &&
      pp.visitSalesPage &&
      (pp.pixel ?? "") !== ""
  )

  if (!pixels || pixels.length <= 0) {
    return null
  }

  const cookie = CheckoutFunctions.getUserCookie()
  let email: string | undefined = cookie?.em
  let firstName: string | undefined = cookie?.fn
  let lastName: string | undefined = cookie?.ln
  let phone: string | undefined = cookie?.ph
  let city: string | undefined = cookie?.ct
  let state: string | undefined = cookie?.st
  let zipCode: string | undefined = cookie?.zp
  let country: string | undefined = "BR"

  if ((customerEmail ?? "") !== "") {
    email = customerEmail!.toLowerCase()
  }

  if ((customerName ?? "") !== "") {
    const names = customerName!.toLowerCase().split(" ")
    firstName = names
      .map((value, index) => (index < names.length - 1 ? value : ""))
      .join(" ")
    lastName = names[names.length - 1]
  }

  if ((customerPhone ?? "") !== "") {
    phone = `55${numberOnly(customerPhone)}`
  }

  if ((customerCity ?? "") !== "") {
    city = customerCity!.toLowerCase()
  }

  if ((customerState ?? "") !== "") {
    state = customerState!.toLowerCase()
  }

  if ((customerZipCode ?? "") !== "") {
    zipCode = numberOnly(customerZipCode)
  }

  if ((customerCountry ?? "") !== "") {
    country = customerCountry!.toLowerCase().substring(0, 2)
  }

  const userData = {
    em: email,
    fn: firstName,
    ln: lastName,
    ph: phone,
    ct: city,
    st: state,
    zp: zipCode,
    country: country,
    external_id: Convertions.encryptSha256(numberOnly(customerDocument)),
  }

  const customData = {
    content_type: "product",
    value: productValue,
    currency: "BRL",
    product_id: productCode,
    content_name: productName,
    content_category: productName,
    content_ids: [productPriceCode],
    contents: [productPriceCode],
    // custom data
    ...userData,
  }

  const fbqInit = pixels
    .map((p) => `fbq('init', '${p.pixel}', ${JSON.stringify(userData)});`)
    .join("\n")

  const script = TagFunctions.addScriptHeader(
    `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        ${fbqInit}
        fbq('track', 'PageView');
        fbq('track', "InitiateCheckout", ${JSON.stringify(
          customData
        )}, {eventID: '${eventId}'});`
  )

  return { script }
}

const addTagAddPaymentInfo = (
  eventId: string,
  productPriceCode: string,
  productCode: string,
  productName: string,
  productValue: number,
  paymentMethod: string,
  productPixels?: TProductPixel[],
  customerEmail?: string,
  customerName?: string,
  customerPhone?: string,
  customerDocument?: string,
  customerCity?: string,
  customerState?: string,
  customerZipCode?: string,
  customerCountry?: string
): ITagsProp | null => {
  if (!productPixels || productPixels.length <= 0) {
    return null
  }

  const pixels = productPixels.filter(
    (pp) =>
      pp.type === TProductPixelType.Facebook &&
      pp.selectivePurchase &&
      (pp.pixel ?? "") !== ""
  )

  if (!pixels || pixels.length <= 0) {
    return null
  }

  let email: string | undefined = undefined
  let firstName: string | undefined = undefined
  let lastName: string | undefined = undefined
  let phone: string | undefined = undefined
  let city: string | undefined = undefined
  let state: string | undefined = undefined
  let zipCode: string | undefined = undefined
  let country: string | undefined = undefined

  if ((customerEmail ?? "") !== "") {
    email = customerEmail!.toLowerCase()
  }

  if ((customerName ?? "") !== "") {
    const names = customerName!.toLowerCase().split(" ")
    firstName = names
      .map((value, index) => (index < names.length - 1 ? value : ""))
      .join(" ")
    lastName = names[names.length - 1]
  }

  if ((customerPhone ?? "") !== "") {
    phone = `55${numberOnly(customerPhone)}`
  }

  if ((customerCity ?? "") !== "") {
    city = customerCity!.toLowerCase()
  }

  if ((customerState ?? "") !== "") {
    state = customerState!.toLowerCase()
  }

  if ((customerZipCode ?? "") !== "") {
    zipCode = numberOnly(customerZipCode)
  }

  if ((customerCountry ?? "") !== "") {
    country = customerCountry!.toLowerCase().substring(0, 2)
  }

  const customData = {
    content_type: "product",
    value: productValue,
    currency: "BRL",
    product_id: productCode,
    content_name: productName,
    content_category: productName,
    content_ids: [productPriceCode],
    contents: [productPriceCode],
    payment_method: paymentMethod,
    // custom data
    em: email,
    fn: firstName,
    ln: lastName,
    ph: phone,
    ct: city,
    st: state,
    zp: zipCode,
    country: country,
    external_id: Convertions.encryptSha256(numberOnly(customerDocument)),
  }

  const script = TagFunctions.addScriptHeader(
    `fbq('track', "AddPaymentInfo", ${JSON.stringify(
      customData
    )}, {eventID: '${eventId}'});`
  )

  return { script }
}

const addTagPurchase = (sale: TSaleStorage): ITagsProp | null => {
  if (!includesMetaTag(sale)) {
    return null
  }

  const meta: TMetaPurchase = {
    content_type: "product",
    value: sale.productValue ?? 0,
    currency: "BRL",
    product_id: sale.productCode,
    content_name: sale.productName,
    content_ids: [sale.productPriceCode],
    contents: [sale.productPriceCode],
    num_items: 1,
  }

  const names = sale.customer.name.toLowerCase().split(" ")
  const firstName = names
    .map((value, index) => (index < names.length - 1 ? value : ""))
    .join(" ")
  const lastName = names[names.length - 1]

  const phone = `55${sale.customer.phone}`

  const metaCustomer = {
    em: sale.customer.email.toLowerCase(),
    fn: firstName,
    ln: lastName,
    ph: phone,
    ct: sale.customer.city.toLowerCase(),
    st: sale.customer.state.toLowerCase(),
    zp: numberOnly(sale.customer.zipCode),
    country: sale.customer.country.toLowerCase().substring(0, 2),
    external_id: Convertions.encryptSha256(numberOnly(sale.customer.document)),
  }

  const fbqInit = getMetaTags(sale)
    .map((p) => `fbq('init', '${p.pixel}', ${JSON.stringify(metaCustomer)});`)
    .join("\n")

  const script = TagFunctions.addScriptHeader(
    `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        ${fbqInit}
        fbq('track', 'PageView');
        fbq('track', 'Purchase', ${JSON.stringify(meta)}, {eventID: '${
      sale.orderCode
    }'});`
  )

  return { script }
}

const sendPurchaseAPI = async (sale: TSaleStorage) => {
  if (!includesMetaApi(sale)) {
    return false
  }

  const pixels = getMetaApis(sale)
  const { fbc, fbp } = getFbpAndFbc()
  const geo = await HostFunctions.getGeolocation()

  const names = sale.customer.name.toLowerCase().split(" ")
  const firstName = names
    .map((value, index) => (index < names.length - 1 ? value : ""))
    .join(" ")
  const lastName = names[names.length - 1]

  const phone = `55${numberOnly(sale.customer.phone)}`

  let city = sale.customer.city
  let zipCode = numberOnly(sale.customer.zipCode)

  if (!city || city === "") {
    city = geo?.city ?? ""
  }

  if (!zipCode || zipCode === "") {
    zipCode = numberOnly(geo?.postal ?? "")
  }

  await Promise.all(
    pixels.map(async (pixel) => {
      const current_timestamp = Math.floor(new Date().getTime() / 1000)

      const data = {
        id: pixel.id,
        data: [
          {
            eventName: "Purchase",
            eventTime: current_timestamp,
            eventId: sale.orderCode,
            eventSourceUrl:
              (pixel.pixelDomain ?? "") !== ""
                ? `https:\/\/${pixel.pixelDomain}\/${sale.productPriceCode}`
                : sale.redirectUrl,
            actionSource: "website",
            userData: {
              clientIpAddress: geo?.IPv4,
              clientUserAgent: window.navigator.userAgent,
              externalId: [
                Convertions.encryptSha256(numberOnly(sale.customer.document)),
              ],
              em: [Convertions.encryptSha256(sale.customer.email)],
              ph: [Convertions.encryptSha256(phone)],
              fn: firstName
                .trim()
                .split(" ")
                .map((fn) => Convertions.encryptSha256(fn)),
              ln: [Convertions.encryptSha256(lastName)],
              ct: [Convertions.encryptSha256(city)],
              st: [Convertions.encryptSha256(sale.customer.state)],
              zp: [Convertions.encryptSha256(zipCode)],
              country: [
                Convertions.encryptSha256(
                  (geo?.country_code ?? "BR").toLowerCase().substring(0, 2)
                ),
              ],
              fbc,
              fbp,
              name: sale.customer.name,
            },
            customData: {
              value: sale.productValue || 0,
              currency: "BRL",
              contentIds: [sale.productPriceCode],
              contentType: "product",
              productId: sale.productCode,
              paymentMethod: Convertions.methodTypeToPaymentMethod(
                sale.paymentType
              ),
            },
            optOut: false,
          },
        ],
      }

      try {
        await ProductController.sendFacebookPurchase({ data })
      } catch (e) {
        console.error(e)
      }
    })
  )

  return true
}

const sendCheckoutAPI = async (
  eventId: string,
  productPriceCode?: string,
  productCode?: string,
  productValue?: number,
  productPixels?: TProductPixel[],
  customerEmail?: string,
  customerName?: string,
  customerPhone?: string,
  customerDocument?: string,
  customerCity?: string,
  customerState?: string,
  customerZipCode?: string,
  customerCountry?: string
) => {
  if (!productPixels || productPixels.length <= 0) {
    return null
  }

  const pixels = productPixels.filter(
    (pp) =>
      pp.type === TProductPixelType.Facebook &&
      pp.visitSalesPage &&
      (pp.pixelToken ?? "") !== "" &&
      pp.cnameVerified
  )

  if (!pixels || pixels.length <= 0) {
    return null
  }

  const { fbc, fbp } = getFbpAndFbc()
  const geo = await HostFunctions.getGeolocation()

  let email: string[] | undefined = undefined
  let firstName: string[] | undefined = undefined
  let lastName: string[] | undefined = undefined
  let phone: string[] | undefined = undefined
  let city: string[] | undefined = undefined
  let state: string[] | undefined = undefined
  let zipCode: string[] | undefined = undefined
  let country: string[] | undefined = undefined
  let name: string = customerName ?? ""

  const cookie = CheckoutFunctions.getUserCookie()
  if (cookie) {
    email = [cookie.em]
    firstName = [cookie.fn]
    lastName = [cookie.ln]
    phone = [cookie.ph]
    city = [cookie.ct]
    state = [cookie.st]
    zipCode = [cookie.zp]
    name = cookie.name ?? ""
  }

  if ((customerEmail ?? "") !== "") {
    email = [Convertions.encryptSha256(customerEmail!.toLowerCase())]
  }

  if ((customerName ?? "") !== "") {
    const names = customerName!.toLowerCase().split(" ")
    firstName = names
      .map((value, index) =>
        index < names.length - 1 ? Convertions.encryptSha256(value) : ""
      )
      .filter((n) => n.trim() !== "")
    lastName = [Convertions.encryptSha256(names[names.length - 1])]
  }

  if ((customerPhone ?? "") !== "") {
    phone = [Convertions.encryptSha256(`55${numberOnly(customerPhone)}`)]
  }

  if ((customerCity ?? "") !== "") {
    city = [Convertions.encryptSha256(customerCity!.toLowerCase())]
  } else {
    if ((geo?.city ?? "") !== "") {
      city = [Convertions.encryptSha256(geo?.city ?? "")]
    }
  }

  if ((customerState ?? "") !== "") {
    state = [Convertions.encryptSha256(customerState!.toLowerCase())]
  }

  if ((customerZipCode ?? "") !== "") {
    zipCode = [Convertions.encryptSha256(numberOnly(customerZipCode))]
  } else {
    if ((geo?.postal ?? "") !== "") {
      zipCode = [Convertions.encryptSha256(geo?.postal ?? "")]
    }
  }

  if ((customerCountry ?? "") !== "") {
    country = [
      Convertions.encryptSha256(customerCountry!.toLowerCase().substring(0, 2)),
    ]
  } else {
    country = [
      Convertions.encryptSha256(
        (geo?.country_code ?? "BR").toLowerCase().substring(0, 2)
      ),
    ]
  }

  await Promise.all(
    pixels.map(async (pixel) => {
      let current_timestamp = Math.floor(new Date().getTime() / 1000)

      const data = {
        id: pixel.id,
        data: [
          {
            eventName: "InitiateCheckout",
            eventTime: current_timestamp,
            eventId: eventId,
            eventSourceUrl: `https:\/\/${pixel.pixelDomain}\/${productPriceCode}`,
            actionSource: "website",
            userData: {
              clientIpAddress: geo?.IPv4,
              clientUserAgent: window.navigator.userAgent,
              externalId: [
                Convertions.encryptSha256(numberOnly(customerDocument)),
              ],
              em: email,
              ph: phone,
              fn: firstName,
              ln: lastName,
              ct: city,
              st: state,
              zp: zipCode,
              country,
              fbc,
              fbp,
              name: name,
            },
            customData: {
              value: productValue ?? 0,
              currency: "BRL",
              contentIds: [productPriceCode],
              contentType: "product",
              productId: productCode,
            },
            optOut: false,
          },
        ],
      }

      try {
        await ProductController.sendFacebookCheckout({ data })
      } catch (e) {
        console.error(e)
      }
    })
  )

  return true
}

const sendAddPaymentInfoAPI = async (
  eventId: string,
  productPriceCode?: string,
  productCode?: string,
  productValue?: number,
  paymentMethod?: string,
  productPixels?: TProductPixel[],
  customerEmail?: string,
  customerName?: string,
  customerPhone?: string,
  customerDocument?: string,
  customerCity?: string,
  customerState?: string,
  customerZipCode?: string,
  customerCountry?: string
) => {
  if (!productPixels || productPixels.length <= 0) {
    return null
  }

  const pixels = productPixels.filter(
    (pp) =>
      pp.type === TProductPixelType.Facebook &&
      pp.selectivePurchase &&
      (pp.pixelToken ?? "") !== "" &&
      pp.cnameVerified
  )

  if (!pixels || pixels.length <= 0) {
    return null
  }

  const { fbc, fbp } = getFbpAndFbc()
  const geo = await HostFunctions.getGeolocation()

  let email: string[] | undefined = undefined
  let firstName: string[] | undefined = undefined
  let lastName: string[] | undefined = undefined
  let phone: string[] | undefined = undefined
  let city: string[] | undefined = undefined
  let state: string[] | undefined = undefined
  let zipCode: string[] | undefined = undefined
  let country: string[] | undefined = undefined

  if ((customerEmail ?? "") !== "") {
    email = [Convertions.encryptSha256(customerEmail!.toLowerCase())]
  }

  if ((customerName ?? "") !== "") {
    const names = customerName!.toLowerCase().split(" ")
    firstName = names
      .map((value, index) =>
        index < names.length - 1 ? Convertions.encryptSha256(value) : ""
      )
      .filter((n) => n.trim() !== "")
    lastName = [Convertions.encryptSha256(names[names.length - 1])]
  }

  if ((customerPhone ?? "") !== "") {
    phone = [Convertions.encryptSha256(`55${numberOnly(customerPhone)}`)]
  }

  if ((customerCity ?? "") !== "") {
    city = [Convertions.encryptSha256(customerCity!.toLowerCase())]
  }

  if ((customerState ?? "") !== "") {
    state = [Convertions.encryptSha256(customerState!.toLowerCase())]
  }

  if ((customerZipCode ?? "") !== "") {
    zipCode = [Convertions.encryptSha256(numberOnly(customerZipCode))]
  }

  if ((customerCountry ?? "") !== "") {
    country = [
      Convertions.encryptSha256(customerCountry!.toLowerCase().substring(0, 2)),
    ]
  } else {
    country = [
      Convertions.encryptSha256(
        (geo?.country_code ?? "BR").toLowerCase().substring(0, 2)
      ),
    ]
  }

  await Promise.all(
    pixels.map(async (pixel) => {
      let current_timestamp = Math.floor(new Date().getTime() / 1000)

      const data = {
        id: pixel.id,
        data: [
          {
            eventName: "AddPaymentInfo",
            eventTime: current_timestamp,
            eventId: eventId,
            eventSourceUrl: `https:\/\/${pixel.pixelDomain}\/${productPriceCode}`,
            actionSource: "website",
            userData: {
              clientIpAddress: geo?.IPv4,
              clientUserAgent: window.navigator.userAgent,
              externalId: [
                Convertions.encryptSha256(numberOnly(customerDocument)),
              ],
              em: email,
              ph: phone,
              fn: firstName,
              ln: lastName,
              ct: city,
              st: state,
              zp: zipCode,
              country,
              fbc,
              fbp,
              name: customerName,
            },
            customData: {
              value: productValue ?? 0,
              currency: "BRL",
              contentIds: [productPriceCode],
              contentType: "product",
              paymentMethod: paymentMethod,
              productId: productCode,
            },
            optOut: false,
          },
        ],
      }

      try {
        await ProductController.sendFacebookAddPaymentInfo({ data })
      } catch (e) {
        console.error(e)
      }
    })
  )

  return true
}

const getMetaTags = (sale: TSaleStorage) => {
  if (!sale.pixels) {
    return []
  }

  let pixels = sale.pixels.filter(
    (p) => p.type === TProductPixelType.Facebook && p.pixel !== ""
  )

  if (
    [TPaymentMethodEnum.CreditCard, TPaymentMethodEnum.DebitCard].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveImmediatePurchase)
  }

  if (
    [TPaymentMethodEnum.Pix, TPaymentMethodEnum.Boleto].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveNonImmediatePurchase)
  }

  return pixels
}

const getMetaApis = (sale: TSaleStorage) => {
  if (!sale.pixels) {
    return []
  }

  let pixels = sale.pixels.filter(
    (p) =>
      p.type === TProductPixelType.Facebook &&
      p.pixel !== "" &&
      (p.pixelToken ?? "") !== "" &&
      p.cnameVerified &&
      p.selectivePurchase
  )

  if (
    [TPaymentMethodEnum.CreditCard, TPaymentMethodEnum.DebitCard].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveImmediatePurchase)
  }

  if (
    [TPaymentMethodEnum.Pix, TPaymentMethodEnum.Boleto].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveNonImmediatePurchase)
  }

  return pixels
}

const includesMetaTag = (sale: TSaleStorage) => {
  return getMetaTags(sale).length > 0
}

const includesMetaApi = (sale: TSaleStorage) => {
  return getMetaApis(sale).length > 0
}

const MetaFunctions = {
  getFbpAndFbc,
  addTagInitiateCheckout,
  addTagAddPaymentInfo,
  addTagPurchase,
  sendPurchaseAPI,
  sendCheckoutAPI,
  sendAddPaymentInfoAPI,
}

export default MetaFunctions
