import { FormControl, Paper, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import StepPaymentItem from "./StepPaymentItem/StepPaymentItem"
import { checkoutPayment4Style } from "./PaymentConsts"
import { TPaymentMethodEnum } from "../../../../../core/types/Product"
import PriceInput from "../../../../Inputs/PriceInput"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { TSale } from "../../../../../core/types/Sale"
import NumberFunctions from "../../../../../core/functions/NumberFunctions"

interface PixPaymentPriceProps {
  visible: boolean
  title?: string
  index: number
  isMultiple?: boolean
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
}

const PixPaymentPrice = ({
  visible,
  title,
  index,
  isMultiple = false,
  watch,
  setValue,
}: PixPaymentPriceProps) => {
  const [errorValue, setErrorValue] = useState("")

  const paymentPixValue = watch(`payment.payments.${index}.value`)
  const payments = watch("payment.payments")

  useEffect(() => {
    if ((paymentPixValue ?? 5) < 5) {
      setErrorValue(
        `O valor precisa ser maior que R$ ${NumberFunctions.formatMoneyDefault(
          5
        )}`
      )
    }
  }, [paymentPixValue])

  return (
    <Stack spacing={1}>
      <Paper
        elevation={0}
        sx={{ borderRadius: "8px", backgroundColor: "#F2F2FF" }}
      >
        {title && isMultiple && (
          <Stack direction="column" spacing={2} sx={{ p: "20px" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              {checkoutPayment4Style[TPaymentMethodEnum.Pix].icon[0]}
              <Typography
                variant="body1"
                fontSize="14px"
                fontWeight={600}
                lineHeight="18px"
              >
                {title}
              </Typography>
            </Stack>

            {isMultiple && (
              <FormControl fullWidth>
                <PriceInput
                  label="Valor a pagar no cartão"
                  value={!paymentPixValue ? 0 : paymentPixValue}
                  error={errorValue !== ""}
                  InputProps={{
                    inputProps: { min: 5 },
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errorValue !== "" ? "red" : "#BCC1FF",
                        borderWidth: "2px",
                      },
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: errorValue !== "" ? "red" : "#BCC1FF",
                        borderWidth: "2px",
                      },
                    },
                  }}
                  onChange={(value) => {
                    setValue(`payment.payments.${index}.value`, value)
                    setErrorValue("")
                  }}
                  disabled={index > 0}
                />
                <Typography
                  lineHeight="14px"
                  fontSize="12px"
                  variant="body2"
                  sx={{
                    color: "#5D5E61",
                    pt: 0.5,
                  }}
                >
                  O valor mínimo é R$ {NumberFunctions.formatMoneyDefault(5)}
                </Typography>
                {errorValue !== "" && (
                  <Typography variant="overline" color={"red"}>
                    {errorValue}
                  </Typography>
                )}
              </FormControl>
            )}
          </Stack>
        )}
      </Paper>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        sx={{ display: visible ? undefined : "none" }}
      >
        <StepPaymentItem
          src="/assets/icons/payments/pix/approved.svg"
          title="Aprovação imediata"
          subtitle="O pagamento com Pix leva pouco tempo para ser processado."
        />
        <StepPaymentItem
          src="/assets/icons/payments/pix/security.svg"
          title="Transação segura"
          subtitle="O Pix foi desenvolvido pelo Banco Central para facilitar suas compras, garantindo a proteção dos seus dados."
        />
        <StepPaymentItem
          src="/assets/icons/payments/pix/payment.svg"
          title="Finalize sua compra com facilidade"
          subtitle="É só acessar a área Pix no aplicativo do seu banco e escanear o QR code ou digitar o código."
        />
      </Stack>
    </Stack>
  )
}

export default PixPaymentPrice
