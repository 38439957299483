import { Card, Stack, Typography } from "@mui/material"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
  TProductTypePayment,
} from "../../../../core/types/Product"
import { TSale, TSaleProduct } from "../../../../core/types/Sale"
import React from "react"
import OrderItem4 from "./OrderDetails/OrderItem4"
import OrderProduct4 from "./OrderDetails/OrderProduct4"
import { TProductVoucherValue } from "../../../../core/types/Voucher"
import { UseFormWatch } from "react-hook-form"
import NumberFunctions from "../../../../core/functions/NumberFunctions"
import { useCheckoutContext } from "../../../../core/hooks/useCheckoutContext"

export interface ICheckoutDetails4 {
  data: IProductPrice | null
  products: TSaleProduct[]
  paymentMethodType: number
  paymentInstallment: number
  productInstallments?: IProductPricePaymentInstallment[] | null
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
}

/**
 * Componente responsável por apresentar os detalhes, produtos, valores e total.
 * Em caso de vários pagamentos será exibido as formas de pagamentos e seus valores.
 */
const CheckoutDetails4 = ({
  data,
  products,
  paymentMethodType,
  paymentInstallment,
  productInstallments,
  paymentTotal,
  paymentValue,
  watch,
}: ICheckoutDetails4) => {
  const { installment } = useCheckoutContext()

  const voucherStatus = watch("payment.voucherStatus")
  const voucherType = watch("payment.voucherType")
  const voucherValue = watch("payment.voucherValue")
  const paymentDiscount = watch("payment.discount")
  const payments = watch("payment.payments")

  const isVoucher = voucherStatus === "valid"

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          elevation={0}
          sx={{
            borderRadius: "8px",
            backgroundColor: "#F2F2FF",
            py: 2,
            px: 3,
            width: { sm: "100%", xs: "100%" },
          }}
        >
          <Stack
            direction="row"
            spacing={3}
            alignContent="center"
            alignItems="center"
          >
            <img src="/assets/icons/payments/shield.svg" alt="" />
            <Stack direction="column">
              <Typography
                variant="h6"
                fontSize="16px"
                fontWeight={700}
                lineHeight="20px"
                sx={{ color: "#38393B" }}
              >
                Ambiente 100% seguro
              </Typography>

              <Typography
                fontSize="14px"
                fontWeight={400}
                lineHeight="18px"
                sx={{ color: "#707275" }}
              >
                Processamos os pagamentos com certificado SSL máxima segurança e
                privacidade na sua compra.
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Stack>

      <Typography
        variant="body1"
        fontWeight={700}
        fontSize="16"
        lineHeight="20px"
        gutterBottom
        sx={{ color: "#343948" }}
      >
        Detalhes da compra
      </Typography>

      {data?.typePayment === TProductTypePayment.RecurringSubscription &&
        (data?.firstPurchasePrice || 0) > 0 && (
          <OrderItem4
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            description={data?.productName || ""}
            value={data?.firstPurchasePrice || data?.value || 0}
            defaultValue={0}
            installments={productInstallments}
            typePayment={data?.typePayment}
            frequency={data?.frequency}
          />
        )}

      {products
        .filter(
          (p) =>
            p.typePayment === TProductTypePayment.RecurringSubscription &&
            (p.firstPurchasePrice || 0) > 0
        )
        .map((product) => (
          <OrderProduct4
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {(data?.firstPurchasePrice || 0) <= 0 && (
        <OrderItem4
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description={data?.productName || ""}
          value={data?.firstPurchasePrice || data?.value || 0}
          defaultValue={0}
          installments={productInstallments}
          typePayment={data?.typePayment}
          frequency={data?.frequency}
        />
      )}

      {products
        .filter((p) => (p.firstPurchasePrice || 0) <= 0)
        .map((product) => (
          <OrderProduct4
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {isVoucher && (
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography
            fontWeight={600}
            lineHeight="18px"
            fontSize="14px"
            sx={{ color: "#707275" }}
          >
            Desconto
            {voucherType === TProductVoucherValue.percentage
              ? ` ${voucherValue}%`
              : ""}
          </Typography>
          <Typography
            fontWeight={500}
            lineHeight="18px"
            fontSize="14px"
            textAlign="right"
            sx={{ color: "#707275" }}
          >
            {NumberFunctions.formatMoneyDefault(paymentDiscount)}
          </Typography>
        </Stack>
      )}

      {payments.length > 1 &&
        payments.map((pay, index) => {
          const label =
            pay.paymentMethod === TPaymentMethodEnum.CreditCard
              ? `${index + 1}º Cartão`
              : "PIX"
          return (
            <OrderItem4
              subtitle
              paymentMethodType={pay.paymentMethod}
              installmentNumber={paymentInstallment}
              description={label}
              value={pay.value ?? 0}
              defaultValue={pay.value ?? 0}
              installments={pay.installments}
              typePayment={data?.typePayment}
            />
          )
        })}

      {payments.length <= 1 && (
        <OrderItem4
          subtitle
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description="Valor Total"
          value={paymentTotal}
          defaultValue={paymentValue}
          installments={installment?.checkout}
          typePayment={data?.typePayment}
        />
      )}
    </Stack>
  )
}

export default CheckoutDetails4
