import Cookies from "js-cookie"
import OrderController from "../../core/controllers/OrderController"
import BrowserFunctions from "../../core/functions/BrowserFunctions"
import MetaFunctions from "../../core/functions/MetaFunctions"
import NumberFunctions from "../../core/functions/NumberFunctions"
import {
  TCheckoutAccess,
  TCheckoutInstallmentsContext,
} from "../../core/types/Checkout"
import { IErrorResponse } from "../../core/types/ErrorResponse"
import { ICalculateInstallment } from "../../core/types/Order"
import { IOrderBump } from "../../core/types/OrderBump"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TProductLinkAffiliatedCookie,
  TProductTypePayment,
} from "../../core/types/Product"
import { TSale, TSaleProduct, TVoucherStatus } from "../../core/types/Sale"
import { TProductVoucherValue } from "../../core/types/Voucher"
import { TLostSale } from "../../core/types/LostSale"
import { numberOnly } from "../../masks/masks"
import { UseFormSetValue } from "react-hook-form"
import { diffHours } from "../../core/functions/dateTime"

export type TCalcInstallmentValuesResult = {
  value: number
  subtotal: number
  discount: number
  total: number
}

export type TAddOrRemoveOrderBumpResult = {
  error: string
  newProducts: TSaleProduct[]
  newValue: number
}

const calcInstallmentValues = (
  voucherStatus: TVoucherStatus,
  voucherValue: number | null | undefined,
  voucherValueType: TProductVoucherValue | null | undefined,
  data: IProductPrice | null,
  products: TSaleProduct[]
): TCalcInstallmentValuesResult => {
  if (data) {
    const value = NumberFunctions.round(
      data?.firstPurchasePrice ?? data?.value ?? 0
    )
    const total = NumberFunctions.round(
      value +
        products.reduce(
          (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
          0
        )
    )
    const discount = NumberFunctions.round(
      voucherStatus === "valid" && voucherValue
        ? voucherValueType === TProductVoucherValue.value
          ? voucherValue
          : (total * voucherValue) / 100
        : 0
    )
    return { value, subtotal: total, discount, total: total - discount }
  }

  return { value: 0.0, subtotal: 0.0, discount: 0.0, total: 0.0 }
}

const calcPurchaseNewValue = (
  data: IProductPrice | null,
  products: TSaleProduct[]
): number => {
  return (
    (data?.firstPurchasePrice ?? data?.value ?? 0) +
    products.reduce(
      (acc, curr) => acc + (curr.firstPurchasePrice ?? curr.value),
      0
    )
  )
}

const addOrRemoveOrderBumps = async (
  orderBump: IOrderBump,
  data: IProductPrice | null,
  installment: TCheckoutInstallmentsContext | undefined,
  priceId: string,
  products: TSaleProduct[]
): Promise<TAddOrRemoveOrderBumpResult> => {
  let newValue = data?.firstPurchasePrice || data?.value || 0

  if (orderBump.selected) {
    const orderBumpValue =
      orderBump.productPrice?.firstPurchasePrice ||
      orderBump.productPrice?.value ||
      0
    var installments: IProductPricePaymentInstallment[] = []

    if ((installment?.main ?? []).length > 0) {
      if (!orderBump.installments || orderBump.installments.length <= 0) {
        const response = await OrderController.calculeIsntallments({
          priceId,
          newValue: orderBumpValue,
        })
        const responseError = response as IErrorResponse
        const responseData = response as ICalculateInstallment

        if (responseError.code) {
          return { error: responseError.error, newValue: 0.0, newProducts: [] }
        } else {
          installments = responseData.installments
        }
      } else {
        installments = orderBump.installments ?? []
      }
    }

    products.push({
      id: "",
      productId: orderBump.productPrice?.product?.id ?? "",
      productPriceId: orderBump.productPrice?.id ?? "",
      productName: orderBump.productPrice?.product?.name ?? "",
      productCoverUrl: orderBump.productPrice?.product?.coverUrl ?? "",
      amount: 1,
      value: orderBump.productPrice?.value ?? 0,
      installments,
      typePayment:
        orderBump.productPrice?.product?.typePayment ??
        TProductTypePayment.OneTimePayment,
      frequency: orderBump.productPrice?.frequency,
      firstPurchasePrice: orderBump.productPrice?.firstPurchasePrice,
    })

    newValue = calcPurchaseNewValue(data, products)
    return { error: "", newValue, newProducts: products }
  } else {
    const newProducts = products.filter(
      (product) => product.productPriceId !== orderBump.productPrice?.id
    )

    newValue = calcPurchaseNewValue(data, newProducts)
    return { error: "", newValue, newProducts }
  }
}

const getPageAccess = (
  clientIp: string,
  price: IProductPrice,
  codeId: string | undefined,
  affiliatedCode: string | null | undefined,
  lostSaleCode: string | null | undefined,
  fbclid: string | null | undefined
) => {
  const infos = BrowserFunctions.getInfo()
  const { fbp, fbc } = MetaFunctions.getFbpAndFbc()

  const access: TCheckoutAccess = {
    tenantId: price.tenantId ?? "",
    tenantName: price.tenantName ?? "",
    productId: price.productId ?? "",
    productPriceId: price.id,
    productCode: codeId ?? "",
    productName: price.productName ?? "",
    checkoutModelId: price.checkoutModelId ?? "",
    checkoutModelName: price.checkoutModelName ?? "",
    affiliatedCode: affiliatedCode ?? undefined,
    clientIp,
    userAgent: infos.userAgent,
    browser: infos.browser,
    language: infos.language,
    webdriver: infos.webdriver,
    cookieEnabled: infos.cookieEnabled,
    lostSaleCode,
    pageUrl: window.location.href,
    fbclid,
    fbp,
    fbc,
  }
  return access
}

const identifyFacebookPix = (query: URLSearchParams) => {
  const _fbp = query.get("fbp") ?? query.get("_fbp") ?? ""
  const _fbc = query.get("fbc") ?? query.get("_fbc") ?? ""
  const _fbclid = query.get("fbclid") ?? query.get("_fbclid") ?? ""
  Cookies.set("_apfbp", _fbp)
  Cookies.set("_apfbc", _fbc)
  Cookies.set("_apfbclid", _fbclid)
}

const getLostSale = (
  lostSaleCodeId: string | undefined,
  productPriceId: string | undefined,
  affiliatedCode: string | null | undefined,
  name: string,
  email: string | undefined,
  phone: string | undefined,
  zipCode: string | undefined,
  pageAccessId: string | null | undefined,
  clientIp: string | null | undefined
) => {
  const infos = BrowserFunctions.getInfo()
  const lostSale: TLostSale = {
    codeId: lostSaleCodeId,
    productPriceId,
    affiliatedCode,
    name: name,
    email: email?.trim().toLowerCase(),
    phone: numberOnly(phone),
    cep: numberOnly(zipCode),
    pageAccessId,
    clientIp,
    browser: infos.browser,
  }
  return lostSale
}

const setProductLinkAffiliatedCookie = (
  productLinkAffiliated: TProductLinkAffiliatedCookie | undefined,
  affiliatedCodeId: string,
  setValue: UseFormSetValue<TSale>
) => {
  if (productLinkAffiliated) {
    if (productLinkAffiliated.afid === affiliatedCodeId) {
      if (productLinkAffiliated.src) {
        setValue("src", productLinkAffiliated.src)
      }
      if (productLinkAffiliated.sck) {
        setValue("sck", productLinkAffiliated.sck)
      }
      if (productLinkAffiliated.utm_campaign) {
        setValue("utm_campaign", productLinkAffiliated.utm_campaign)
      }
      if (productLinkAffiliated.utm_content) {
        setValue("utm_content", productLinkAffiliated.utm_content)
      }
      if (productLinkAffiliated.utm_medium) {
        setValue("utm_medium", productLinkAffiliated.utm_medium)
      }
      if (productLinkAffiliated.utm_source) {
        setValue("utm_source", productLinkAffiliated.utm_source)
      }
      if (productLinkAffiliated.utm_term) {
        setValue("utm_term", productLinkAffiliated.utm_term)
      }
    }
  }
}

const getUsedAffiliated = (
  query: URLSearchParams,
  code: string,
  productLinkAffiliated: React.MutableRefObject<
    TProductLinkAffiliatedCookie | undefined
  >
) => {
  let afid = query.get("afid")
  let plid = query.get("pa")
  let afcd: Date | null | undefined

  if (!afid || afid === "") {
    // validar link de outros produtos que expira em 1 hora
    const cookieOther = Cookies.get("__apop")
    if (cookieOther) {
      try {
        productLinkAffiliated.current = JSON.parse(
          cookieOther
        ) as TProductLinkAffiliatedCookie

        const linkCreated = new Date(productLinkAffiliated.current.cre)
        const diffInHours = diffHours(new Date(), linkCreated)

        if (diffInHours <= 1 && productLinkAffiliated.current) {
          afid = productLinkAffiliated.current.afid
          afcd = linkCreated
        }
      } catch (e) {}
    }
  }

  if (!afid || afid === "") {
    const cookieValue = Cookies.get(`_ap_${code}`)
    if (cookieValue) {
      try {
        productLinkAffiliated.current = JSON.parse(
          cookieValue
        ) as TProductLinkAffiliatedCookie
        if (productLinkAffiliated.current) {
          afid = productLinkAffiliated.current.afid
          afcd = new Date(productLinkAffiliated.current.cre)
        }
      } catch (e) {}
    }
  }

  return { afid, plid, afcd }
}

const CheckoutPageFunctions = {
  /**
   * Calcula o valor total da compra com ou sem desconto
   * @param voucherStatus Status do voucher
   * @param voucherValue Valor do voucher
   * @param voucherValueType Tipo do voucher
   * @param data Dados do produto principal
   * @param products Produtos adicionais (Order Bumps)
   * @returns Valor total da compra, subtotal, desconto e valor final
   */
  calcInstallmentValues,
  /**
   * Calcula o valor total da compra, levando em consideração o valor de primeira compra do produto de assinatura
   * @param data Dados do produto principal
   * @param products Produtos adicionais (Order Bumps)
   * @returns Valor total da compra
   */
  calcPurchaseNewValue,
  /**
   * Adiciona ou remove um Order Bump da compra
   * @param orderBump Order Bump
   * @param data Dados do produto principal
   * @param installment Parcelas
   * @param priceId ID do preço
   * @param products Produtos adicionais (Order Bumps)
   * @returns Erro, novo valor da compra e novos produtos
   */
  addOrRemoveOrderBumps,
  /**
   * Retorna os dados de acesso à página
   * @param clientIp IP do cliente
   * @param price Dados do produto
   * @param codeId ID do código
   * @param affiliatedCode Código de afiliado
   * @param lostSaleCode Código de venda perdida
   * @param fbclid FBCLID
   * @returns Dados de acesso
   */
  getPageAccess,
  /**
   * Identifica o Facebook Pixel e salva no cookie
   * @param query Parâmetros da URL
   */
  identifyFacebookPix,
  /**
   * Retorna os dados de uma venda perdida
   * @param lostSaleCodeId ID do código de venda perdida
   * @param productPriceId ID do preço do produto
   * @param affiliatedCode Código de afiliado
   * @param name Nome do cliente
   * @param email E-mail do cliente
   * @param phone Telefone do cliente
   * @param zipCode CEP do cliente
   * @param pageAccessId ID do acesso à página
   * @param clientIp IP do cliente
   * @returns Dados da venda perdida
   */
  getLostSale,
  /**
   * Salva os dados do link afiliado no cookie
   * @param productLinkAffiliated Dados do link afiliado
   * @param affiliatedCodeId ID do código de afiliado
   * @param setValue Função de set do formulário
   */
  setProductLinkAffiliatedCookie,
  /**
   * Retorna os dados do link afiliado
   * @param query Parâmetros da URL
   * @param code Código do produto
   * @param productLinkAffiliated Dados do link afiliado
   * @returns Dados do link afiliado
   */
  getUsedAffiliated,
}

export default CheckoutPageFunctions
