import { Button, CircularProgress, Grid, Stack } from "@mui/material"
import {
  IProductPrice,
  IProductPricePayment,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
} from "../../../core/types/Product"
import {
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
  Control,
  UseFormClearErrors,
} from "react-hook-form"
import { TSale } from "../../../core/types/Sale"
import React from "react"
import CreditCardPaymentPrice from "./CreditCard"
import DebitCardPaymentPrice from "./DebitCard"
import PixPaymentPrice from "./Pix"
import BoletoPaymentPrice from "./Boleto"
import CheckedIcon from "@mui/icons-material/CheckCircle"
import UncheckedIcon from "@mui/icons-material/CircleOutlined"
import { green, grey } from "@mui/material/colors"
import { TCheckoutLayout } from "../../../core/types/Checkout"
import { checkoutPaymentStyle } from "./PaymentConsts"
import PaymentFunctions from "./PaymentFunctions"

export interface IPaymentsByProductProps {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

const PaymentsByProduct = (props: IPaymentsByProductProps) => {
  const {
    layout,
    loading,
    data,
    setValue,
    control,
    errors,
    watch,
    installments,
    clearErrors,
  } = props
  const [selected, setSelected] = React.useState(0)

  const paymentMethodType = watch("payment.methodType")
  const payments = watch("payment.payments")
  //const paymentCardName = watch("payment.cardName")
  //const paymentCardValidate = watch("payment.cardValidate")
  //const paymentCardCVV = watch("payment.cardCCV")

  React.useEffect(() => {
    if (paymentMethodType !== selected) {
      setSelected(paymentMethodType)
    }
  }, [paymentMethodType, selected])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue)

    const id = newValue
    setValue("payment.methodType", id)
  }

  const getPaymentIcon = (payment: IProductPricePayment, isValid: boolean) => {
    return checkoutPaymentStyle[payment.methodType].icon[isValid ? 1 : 0]
    /*switch (payment.methodType) {
      case TPaymentMethodEnum.CreditCard:
        return (
          <CreditCardIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
        )
      case TPaymentMethodEnum.DebitCard:
        return <DebitCardIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
      case TPaymentMethodEnum.Pix:
        return <PixIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
      case TPaymentMethodEnum.Boleto:
        return <BoletoIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
      default:
        return <PaymentIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
    }*/
  }

  const getPaymentDescription = (payment: IProductPricePayment) => {
    return checkoutPaymentStyle[payment.methodType].label
    /*switch (payment.methodType) {
      case TPaymentMethodEnum.CreditCard:
        return layout === TCheckoutLayout.Layout3
          ? "Cartão de Crédito"
          : "Crédito"
      case TPaymentMethodEnum.DebitCard:
        return "Débito"
      case TPaymentMethodEnum.Pix:
        return "PIX"
      case TPaymentMethodEnum.Boleto:
        return "Boleto"
      default:
        return ""
    }*/
  }

  const isPaymentValid = (payment: IProductPricePayment) => {
    return PaymentFunctions.isPaymentValid(payments, payment, paymentMethodType)
  }

  return (
    <Stack sx={{ width: "100%" }}>
      {loading && <CircularProgress />}
      {!loading && data !== null && (
        <Stack sx={{ width: "100%" }} spacing={3}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ overflowY: "hidden" }}
            columns={{
              xs: layout === TCheckoutLayout.Layout3 ? 1 : 4,
              sm: 8,
            }}
            data-cy="containerPaymentOptions"
          >
            {data?.paymentMethods.map((payment, index) => (
              <Grid
                item
                key={`pay-${index}`}
                xs={layout === TCheckoutLayout.Layout3 ? 1 : 2}
                sm={4}
              >
                <Button
                  fullWidth
                  id={`buttonPayment${payment.methodType}`}
                  variant="outlined"
                  startIcon={getPaymentIcon(payment, isPaymentValid(payment))}
                  endIcon={
                    payment.methodType === selected ? (
                      <CheckedIcon fontSize="small" color="primary" />
                    ) : (
                      <UncheckedIcon fontSize="small" />
                    )
                  }
                  sx={{
                    p: 1.5,
                    backgroundColor: "white",
                    borderColor: isPaymentValid(payment)
                      ? green[500]
                      : grey[200],
                    color: "#212121",
                    textTransform:
                      layout === TCheckoutLayout.Layout3 ? "none" : undefined,
                  }}
                  onClick={(e) => handleChange(e, payment.methodType)}
                  data-testid={`buttonPayment${payment.methodType}`}
                >
                  {getPaymentDescription(payment)}
                </Button>
              </Grid>
            ))}
          </Grid>

          <CreditCardPaymentPrice
            visible={selected === TPaymentMethodEnum.CreditCard}
            control={control}
            installments={installments}
            index={0}
            watch={watch}
            clearErrors={clearErrors}
          />

          <DebitCardPaymentPrice
            visible={selected === TPaymentMethodEnum.DebitCard}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
          />

          <PixPaymentPrice visible={selected === TPaymentMethodEnum.Pix} />

          <BoletoPaymentPrice
            visible={selected === TPaymentMethodEnum.Boleto}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default PaymentsByProduct
