import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
  Control,
  UseFormClearErrors,
} from "react-hook-form"
import PaymentIcon from "@mui/icons-material/Paid"
import React from "react"
import CreditCardPaymentPrice from "./CreditCard"
import DebitCardIcon from "@mui/icons-material/CreditCardTwoTone"
import DebitCardPaymentPrice from "./DebitCard"
import PixPaymentPrice from "./Pix"
import BoletoPaymentPrice from "./Boleto"
import { TCheckoutLayout } from "../../../../../core/types/Checkout"
import {
  IProductPrice,
  IProductPricePayment,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
} from "../../../../../core/types/Product"
import { TSale } from "../../../../../core/types/Sale"
import PaymentFunctions from "../../../../../pages/Checkout/Payment/PaymentFunctions"

export interface IPaymentsByProduct5Props {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

const PaymentsByProduct5 = (props: IPaymentsByProduct5Props) => {
  const {
    layout,
    loading,
    data,
    setValue,
    control,
    errors,
    watch,
    installments,
    clearErrors,
  } = props

  const maxPaymentLine = 4
  const isMorePayments = (data?.paymentMethods.length ?? 0) > maxPaymentLine
  const initialMethodType = TPaymentMethodEnum.Pix
  const [selected, setSelected] = React.useState(0)
  const [openMorePay, setOpenMorePay] = React.useState(!isMorePayments)

  const paymentMethodType = watch("payment.methodType")
  const payments = watch("payment.payments")

  React.useEffect(() => {
    if (paymentMethodType !== selected) {
      setSelected(paymentMethodType)
    }
  }, [paymentMethodType, selected])

  React.useEffect(() => {
    setValue("payment.methodType", initialMethodType)
  }, [setValue])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setOpenMorePay(!openMorePay)
      return
    }

    setSelected(newValue)

    const id = newValue
    setValue("payment.methodType", id)
  }

  const getPaymentMethods = () => {
    const paymentMethods = data?.paymentMethods ?? []
    const pixMethod = paymentMethods.find(
      (pm) => pm.methodType === TPaymentMethodEnum.Pix
    )
    const otherMethods = paymentMethods.filter(
      (pm) => pm.methodType !== TPaymentMethodEnum.Pix
    )

    return isMorePayments && !openMorePay
      ? [pixMethod, ...otherMethods].filter((pm, i) => i < maxPaymentLine)
      : [pixMethod, ...otherMethods]
  }

  const getPaymentIcon = (
    payment: IProductPricePayment | undefined,
    isValid: boolean
  ) => {
    if (!payment) {
      return (
        <img
          src={`/assets/icons/payments/plus.svg`}
          alt={openMorePay ? "Menos" : "Mais"}
        />
      )
    }
    switch (payment.methodType) {
      case TPaymentMethodEnum.CreditCard:
        return (
          <img
            src={`/assets/icons/payments/credit-card${
              isValid ? "-selected" : ""
            }.svg`}
            alt="Cartão de Crédito"
          />
        )
      case TPaymentMethodEnum.DebitCard:
        return <DebitCardIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
      case TPaymentMethodEnum.Pix:
        return (
          <img
            src={`/assets/icons/payments/pix${isValid ? "-selected" : ""}.svg`}
            alt="PIX"
          />
        )
      case TPaymentMethodEnum.Boleto:
        return (
          <img
            src={`/assets/icons/payments/barcode${
              isValid ? "-selected" : ""
            }.svg`}
            alt="Boleto"
          />
        )
      default:
        return <PaymentIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
    }
  }

  const getPaymentDescription = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return openMorePay ? "Menos" : "Mais"
    }

    switch (payment.methodType) {
      case TPaymentMethodEnum.CreditCard:
        return isSmallScreen ? "Cartão de \nCrédito" : "Cartão de Crédito"
      case TPaymentMethodEnum.DebitCard:
        return "Débito"
      case TPaymentMethodEnum.Pix:
        return "PIX"
      case TPaymentMethodEnum.Boleto:
        return "Boleto"
      default:
        return ""
    }
  }

  const isPaymentValid = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return false
    }

    return PaymentFunctions.isPaymentValid(payments, payment, paymentMethodType)
  }

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  )

  const getPaymentItem = (
    payment: IProductPricePayment | undefined,
    index: number
  ) => {
    return (
      <Stack direction={"row"} sx={{ marginRight: 1 }}>
        <Button
          fullWidth
          id={`buttonPayment${payment?.methodType ?? 0}`}
          variant="outlined"
          sx={{
            width: isSmallScreen ? "80px" : "180px",
            height: isSmallScreen ? "76px" : "82px",

            borderRadius: "4px ",
            border: "1px 0px 0px 0px",
            gap: "8px",
            borderColor: isPaymentValid(payment) ? "#1C52BD" : "#C5C6C9",
            color: "#212121",
            textTransform: "none",
          }}
          onClick={(e) => handleChange(e, payment?.methodType ?? 0)}
          data-testid={`buttonPayment${payment?.methodType ?? 0}`}
        >
          <Stack
            direction="column"
            spacing={isSmallScreen ? 0 : 1}
            alignContent="center"
            alignItems="center"
          >
            {getPaymentIcon(payment, isPaymentValid(payment))}
            <Typography
              textAlign="center"
              fontSize={isSmallScreen ? "13px" : "14px"}
              lineHeight="18px"
              whiteSpace="nowrap"
            >
              {getPaymentDescription(payment) === "Cartão de \nCrédito"
                ? getPaymentDescription(payment)
                    .split("\n")
                    .map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))
                : getPaymentDescription(payment)}
            </Typography>
          </Stack>
        </Button>
      </Stack>
    )
  }

  return (
    <Stack sx={{ width: "100%" }}>
      {loading && <CircularProgress />}
      {!loading && data !== null && (
        <Stack sx={{ width: "100%" }} spacing={1}>
          <Grid
            container
            spacing={1}
            justifyContent="left"
            style={{ overflowY: "hidden" }}
            columns={{
              xs: 1,
              sm: 16,
            }}
            data-cy="containerPaymentOptions"
          >
            {getPaymentMethods()?.map((payment, index) =>
              getPaymentItem(payment, index)
            )}
            {isMorePayments && getPaymentItem(undefined, -1)}
          </Grid>

          <CreditCardPaymentPrice
            visible={selected === TPaymentMethodEnum.CreditCard}
            control={control}
            errors={errors}
            installments={installments}
            index={0}
            watch={watch}
            setValue={setValue}
            clearErrors={clearErrors}
          />

          <DebitCardPaymentPrice
            visible={selected === TPaymentMethodEnum.DebitCard}
            control={control}
            index={0}
            watch={watch}
            clearErrors={clearErrors}
          />

          <PixPaymentPrice visible={selected === TPaymentMethodEnum.Pix} />

          <BoletoPaymentPrice
            visible={selected === TPaymentMethodEnum.Boleto}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default PaymentsByProduct5
