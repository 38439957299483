import { Divider, Paper, Stack } from "@mui/material"
import CheckoutCoverImage from "../Checkout/CheckoutCoverImage"
import CheckoutFunctions from "../../core/functions/CheckoutFunctions"
import CheckoutClient from "../Checkout/CheckoutClient"
import { TCheckoutComponent } from "../../core/types/Checkout"
import CheckoutAddress from "../Checkout/CheckoutAddress"
import CheckoutPayments from "../Checkout/CheckoutPayments"
import CheckoutOrderBumps from "../Checkout/CheckoutOrderBumps"
import CheckoutDetails from "../Checkout/CheckoutDetails"
import CheckoutButtonCommit from "../Checkout/CheckoutButtonCommit"
import CheckoutFooter from "../Checkout/CheckoutFooter"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
} from "../../core/types/Product"
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { TSale, TSaleProduct } from "../../core/types/Sale"
import { IOrderBump } from "../../core/types/OrderBump"
import { isWidget } from "../../core/constants"
import CheckoutImage from "./CheckoutImage"

export interface ICheckoutWidgetProps {
  id: React.MutableRefObject<string>
  loading: boolean
  loadingVoucher: boolean
  isCheckoutBuilder: boolean
  searchedZipCode: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  productInstallments?: IProductPricePaymentInstallment[]
  installments?: IProductPricePaymentInstallment[]
  orderBumps?: IOrderBump[]
  products: TSaleProduct[]
  paymentInstallment: number
  paymentMethodType: number
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>
  setOpenTermsPurchase: (value: boolean) => void
  onSendPixel: () => void
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

const CheckoutWidget = ({
  id,
  loading,
  loadingVoucher,
  isCheckoutBuilder,
  searchedZipCode,
  data,
  control,
  errors,
  productInstallments,
  installments,
  orderBumps,
  products,
  paymentInstallment,
  paymentMethodType,
  paymentTotal,
  paymentValue,
  watch,
  setValue,
  onSubmit,
  onKeyDown,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresComplementKeyDown,
  handleAddresNumberKeyDown,
  orderBumpChange,
  setOpenTermsPurchase,
  onSendPixel,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutWidgetProps) => {
  const isNewLayoutCoverImage = CheckoutFunctions.isNewLayoutCoverImage(
    data?.checkoutComponents
  )

  const isImageCover =
    !isWidget() &&
    !isCheckoutBuilder &&
    data?.productCoverUrl &&
    data?.productCoverUrl !== ""

  const isLayout5 = CheckoutFunctions.isLayout5ModelE(data?.checkoutComponents)

  const isPaymentMultiple =
    data?.paymentMethods.find((pm) =>
      [
        TPaymentMethodEnum.TwoCreditCard,
        TPaymentMethodEnum.CreditCardAndPix,
      ].includes(pm.methodType)
    ) !== undefined

  return (
    <Stack
      noValidate
      component="form"
      direction="column"
      spacing={2}
      onSubmit={onSubmit}
      onKeyDown={onKeyDown}
      sx={{
        pt: {
          sx: 0,
          sm:
            isNewLayoutCoverImage && !isImageCover && !isCheckoutBuilder
              ? "32px"
              : 0,
        },
      }}
    >
      {isNewLayoutCoverImage && isImageCover && (
        <CheckoutImage imageUrl={data?.productCoverUrl} alt="img-top" />
      )}
      <Paper
        square={true}
        elevation={0}
        sx={{
          width: "100%",
          backgroundColor: isLayout5 ? "transparent" : undefined,
          borderRadius: {
            xs: isNewLayoutCoverImage ? "0px" : "8px",
            sm: "8px",
          },
          pb: 2,
        }}
      >
        <CheckoutCoverImage
          layout={CheckoutFunctions.getLayoutComponent(
            TCheckoutComponent.CoverImage,
            data?.checkoutComponents
          )}
          imageUrl={data?.productCoverUrl}
          productImageUrl={data?.productImageUrl}
          productName={data?.productName ?? "..."}
          offerName={data?.offerName ?? ""}
          productDescription={data?.productDescription ?? "..."}
          productInstallments={productInstallments}
          isCheckoutBuilder={isCheckoutBuilder}
          productValue={data?.firstPurchasePrice ?? data?.value ?? 0}
        />

        <Paper
          square={true}
          elevation={0}
          sx={{
            width: "100%",
            borderRadius: {
              xs: isNewLayoutCoverImage ? "0px" : "8px",
              sm: "8px",
            },
            mt: isLayout5 ? 1 : 1.5,
          }}
        >
          <Stack
            direction={"column"}
            width={"100%"}
            sx={{ p: { sm: 3, xs: !isNewLayoutCoverImage ? 1 : 3 } }}
            spacing={2}
            data-cy="containerCheckoutWidget"
          >
            <CheckoutClient
              layout={CheckoutFunctions.getLayoutComponent(
                TCheckoutComponent.ClientsData,
                data?.checkoutComponents
              )}
              control={control}
              setValue={setValue}
              watch={watch}
              saveLostSale={saveLostSale}
              clearErrors={clearErrors}
            />

            <CheckoutAddress
              layout={CheckoutFunctions.getLayoutComponent(
                TCheckoutComponent.AddressData,
                data?.checkoutComponents
              )}
              hideCheckoutAddress={data?.hideCheckoutAddress}
              control={control}
              loading={loading}
              searchedZipCode={searchedZipCode}
              watch={watch}
              saveLostSale={saveLostSale}
              clearErrors={clearErrors}
              searchZipCode={searchZipCode}
              handleAddresComplementKeyDown={handleAddresComplementKeyDown}
              handleAddresNumberKeyDown={handleAddresNumberKeyDown}
              onSendPixel={onSendPixel}
            />

            {!isLayout5 && isPaymentMultiple && <Divider />}

            {isPaymentMultiple && (
              <CheckoutOrderBumps
                layout={CheckoutFunctions.getLayoutComponent(
                  TCheckoutComponent.Orderbumps,
                  data?.checkoutComponents
                )}
                paymentInstallment={paymentInstallment}
                paymentMethodType={paymentMethodType}
                orderBumps={orderBumps}
                orderBumpChange={orderBumpChange}
              />
            )}

            {!isLayout5 &&
              isPaymentMultiple &&
              (orderBumps?.length ?? 0) > 0 && <Divider />}

            <CheckoutPayments
              layout={CheckoutFunctions.getLayoutComponent(
                TCheckoutComponent.PaymentMethods,
                data?.checkoutComponents
              )}
              productPriceId={id.current}
              data={data}
              loading={loading}
              loadingVoucher={loadingVoucher}
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
              installments={installments}
              clearErrors={clearErrors}
              onApplyVoucher={onApplyVoucher}
              onRemoveVoucher={onRemoveVoucher}
            />

            {!isLayout5 && !isPaymentMultiple && <Divider />}

            {!isPaymentMultiple && (
              <CheckoutOrderBumps
                layout={CheckoutFunctions.getLayoutComponent(
                  TCheckoutComponent.Orderbumps,
                  data?.checkoutComponents
                )}
                paymentInstallment={paymentInstallment}
                paymentMethodType={paymentMethodType}
                orderBumps={orderBumps}
                orderBumpChange={orderBumpChange}
              />
            )}

            {!isLayout5 &&
              !isPaymentMultiple &&
              (orderBumps?.length ?? 0) > 0 && <Divider />}

            <CheckoutDetails
              layout={CheckoutFunctions.getLayoutComponent(
                TCheckoutComponent.PurchaseDetails,
                data?.checkoutComponents
              )}
              data={data}
              products={products}
              paymentInstallment={paymentInstallment}
              productInstallments={productInstallments}
              paymentMethodType={paymentMethodType}
              paymentTotal={paymentTotal}
              paymentValue={paymentValue}
              watch={watch}
            />

            <CheckoutButtonCommit
              layout={CheckoutFunctions.getLayoutComponent(
                TCheckoutComponent.PurchaseDetails,
                data?.checkoutComponents
              )}
            />
          </Stack>
        </Paper>

        <CheckoutFooter
          layout={CheckoutFunctions.getLayoutComponent(
            TCheckoutComponent.Baseboard,
            data?.checkoutComponents
          )}
          tenantName={data?.tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      </Paper>
    </Stack>
  )
}

export default CheckoutWidget
