import CreditCardIcon from "@mui/icons-material/CreditCard"
import DebitCardIcon from "@mui/icons-material/CreditCardTwoTone"
import PixIcon from "@mui/icons-material/Pix"
import BoletoIcon from "@mui/icons-material/Receipt"
import { TPaymentMethodEnum } from "../../../core/types/Product"

export const checkoutPaymentStyle = {
  [TPaymentMethodEnum.CreditCard]: {
    icon: {
      [0]: <CreditCardIcon sx={{ color: "#424242" }} />,
      [1]: <CreditCardIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "Crédito",
  },
  [TPaymentMethodEnum.DebitCard]: {
    icon: {
      [0]: <DebitCardIcon sx={{ color: "#424242" }} />,
      [1]: <DebitCardIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "Débito",
  },
  [TPaymentMethodEnum.Pix]: {
    icon: {
      [0]: <PixIcon sx={{ color: "#424242" }} />,
      [1]: <PixIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "PIX",
  },
  [TPaymentMethodEnum.Boleto]: {
    icon: {
      [0]: <BoletoIcon sx={{ color: "#424242" }} />,
      [1]: <BoletoIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "Boleto",
  },
  [TPaymentMethodEnum.BankTransfer]: {
    icon: {
      [0]: <PixIcon sx={{ color: "#424242" }} />,
      [1]: <PixIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "Transferência",
  },
  [TPaymentMethodEnum.TwoCreditCard]: {
    icon: {
      [0]: <CreditCardIcon sx={{ color: "#424242" }} />,
      [1]: <CreditCardIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "2 Cartões",
  },
  [TPaymentMethodEnum.CreditCardAndPix]: {
    icon: {
      [0]: <CreditCardIcon sx={{ color: "#424242" }} />,
      [1]: <CreditCardIcon sx={{ color: "#1b5e20" }} />,
    },
    label: "Crédito e PIX",
  },
}
