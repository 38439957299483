import { Box, Divider, Stack, Typography } from "@mui/material"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
  TProductTypePayment,
} from "../../../../core/types/Product"
import { TSaleProduct } from "../../../../core/types/Sale"
import OrderItem from "../../../../pages/Checkout/OrderProduct/OrderItem"
import OrderProduct from "../../../../pages/Checkout/OrderProduct"
import React from "react"

export interface ICheckoutDetails {
  data: IProductPrice | null
  products: TSaleProduct[]
  paymentMethodType: number
  paymentInstallment: number
  productInstallments?: IProductPricePaymentInstallment[] | null
  paymentTotal: number
  paymentValue: number
}

const CheckoutDetailsDefault = ({
  data,
  products,
  paymentMethodType,
  paymentInstallment,
  productInstallments,
  paymentTotal,
  paymentValue,
}: ICheckoutDetails) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Detalhes da compra
      </Typography>

      {((data?.typePayment === TProductTypePayment.RecurringSubscription &&
        (data?.firstPurchasePrice || 0) > 0) ||
        products.find(
          (p) =>
            p.typePayment === TProductTypePayment.RecurringSubscription &&
            (p.firstPurchasePrice || 0) > 0
        )) && (
        <Box>
          <Typography variant="body1" fontWeight={500} gutterBottom>
            Primeira parcela
          </Typography>
          <Divider />
        </Box>
      )}

      {data?.typePayment === TProductTypePayment.RecurringSubscription &&
        (data?.firstPurchasePrice || 0) > 0 && (
          <OrderItem
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            description={data?.productName || ""}
            value={data?.firstPurchasePrice || data?.value || 0}
            defaultValue={0}
            installments={productInstallments}
            typePayment={data?.typePayment}
            frequency={data?.frequency}
          />
        )}

      {products
        .filter(
          (p) =>
            p.typePayment === TProductTypePayment.RecurringSubscription &&
            (p.firstPurchasePrice || 0) > 0
        )
        .map((product) => (
          <OrderProduct
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {paymentMethodType === TPaymentMethodEnum.CreditCard &&
        ((data?.firstPurchasePrice || 0) <= 0 ||
          products.filter((p) => (p.firstPurchasePrice || 0) <= 0).length >
            0) && (
          <Box>
            <Typography variant="body1" fontWeight={500} gutterBottom>
              Parcelas
            </Typography>
            <Divider />
          </Box>
        )}

      {(data?.firstPurchasePrice || 0) <= 0 && (
        <OrderItem
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description={data?.productName || ""}
          value={data?.firstPurchasePrice || data?.value || 0}
          defaultValue={0}
          installments={productInstallments}
          typePayment={data?.typePayment}
          frequency={data?.frequency}
        />
      )}

      {products
        .filter((p) => (p.firstPurchasePrice || 0) <= 0)
        .map((product) => (
          <OrderProduct
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {products.length > 0 && <Divider />}
      {products.length > 0 && (
        <OrderItem
          subtitle
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description="Total"
          value={paymentTotal}
          defaultValue={paymentValue}
          installments={null}
          typePayment={data?.typePayment}
        />
      )}
    </Stack>
  )
}

export default CheckoutDetailsDefault
