import { Box, Typography, Stack } from "@mui/material"
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { TSale } from "../../../core/types/Sale"
import { creditsCards } from "../../../core/constants"
import { useEffect, useState } from "react"
import { CreditCard } from "../../../core/types/CreditCard"
import TextFieldRequired from "../../../components/Inputs/TextFieldRequired"
import React from "react"

export interface ICreditCardPaymentPriceProps {
  visible: boolean
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  clearErrors: UseFormClearErrors<TSale>
}

const DebitCardPaymentPrice = (props: ICreditCardPaymentPriceProps) => {
  const { visible, control, errors, watch, setValue, clearErrors } = props

  const [creditCard, setCreditCard] = useState<CreditCard | undefined>()

  /*const paymentCardNumber = watch("payment.cardNumber");
    const paymentCardCVV = watch("payment.cardCCV");

    useEffect(() => {
        var exists = false;
        creditsCards.map((cc) => {
            if (cc.compare(paymentCardNumber)) {
                setCreditCard(cc);
                exists = true;
            }
            return true;
        });
        if (!exists) {
            setCreditCard(undefined);
        }
    }, [paymentCardNumber]);

    useEffect(() => {
        if ((paymentCardCVV || "").length === 4) {
            document.getElementById("buttonCommit")?.focus();
        }
    }, [paymentCardCVV]);*/

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ display: visible ? "block" : "none" }}
    >
      <TextFieldRequired
        control={control}
        fieldName="payment.cardName"
        label="Nome Impresso no Cartão"
        placeholder="Digite o nome impresso no cartão"
        clearErrors={clearErrors}
        required={visible}
      />
      <TextFieldRequired
        control={control}
        fieldName="payment.cardNumber"
        label="Número do Cartão"
        placeholder="Digite somente números"
        inputProps={{ inputMode: "numeric", maxLength: 19 }}
        clearErrors={clearErrors}
        required={visible}
        endAdornment={
          creditCard && (
            <img
              src={creditCard.getImageUrl()}
              alt="credit card"
              style={{ height: 30, objectFit: "contain" }}
            />
          )
        }
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <TextFieldRequired
          control={control}
          fieldName="payment.cardValidate"
          label="Validade do Cartão (MM/AA)"
          placeholder="MM/AA"
          inputProps={{ inputMode: "numeric", maxLength: 5 }}
          clearErrors={clearErrors}
          required={visible}
        />
        <TextFieldRequired
          control={control}
          fieldName="payment.cardCCV"
          label="Código de Segurança (CCV)"
          placeholder="999"
          inputProps={{
            inputMode: "numeric",
            maxLength: 4,
          }}
          clearErrors={clearErrors}
          required={visible}
        />
      </Stack>
    </Stack>
  )
}

export default DebitCardPaymentPrice
