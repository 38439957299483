import {
  IProductPrice,
  TPaymentMethodEnum,
  TProductPixel,
  TProductPixelType,
} from "../types/Product"
import { TSaleStorage } from "../types/Sale"
import TagFunctions, { ITagsProp } from "./TagFunctions"

const scriptGoogle = `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PX7QCHZ');`

const noscriptGoogle = "https://www.googletagmanager.com/ns.html?id=GTM-PX7QCHZ"

const srcGoogleAdsPixelCheckout = "https://www.googletagmanager.com/gtag/js?id="

const addTagGoogle = (): ITagsProp => {
  const script = TagFunctions.addScriptHeader(scriptGoogle)

  const noscript = TagFunctions.addNoscriptBody(noscriptGoogle)

  return { script, noscript }
}

const addTagGoogleAdsPixelCheckout = (
  productPixels?: TProductPixel[]
): ITagsProp[] | null => {
  if (!productPixels) {
    return null
  }

  const pixels = productPixels?.filter(
    (p) => p.type === TProductPixelType.GoogleAds && p.visitSalesPage
  )

  if (!pixels || pixels.length <= 0) {
    return null
  }

  const scripts = pixels.map((p) => ({
    script: TagFunctions.addScriptHeaderAsync(
      `${srcGoogleAdsPixelCheckout}${p.pixel}`
    ),
  }))

  const gtags = pixels.map((p) => `gtag('config','${p.pixel}');`).join("\n")

  const script = TagFunctions.addScriptHeader(
    `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    ${gtags}`
  )

  const scriptGtag = { script }

  return [...scripts, scriptGtag]
}

const addTagGoogleAdsPixelPurchase = (
  sale: TSaleStorage
): ITagsProp[] | null => {
  if (!sale.pixels) {
    return null
  }

  let pixels = sale.pixels?.filter(
    (p) =>
      p.type === TProductPixelType.GoogleAds &&
      (p.pixelToken ?? "") !== "" &&
      p.selectivePurchase
  )

  if (
    [TPaymentMethodEnum.CreditCard, TPaymentMethodEnum.DebitCard].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveImmediatePurchase)
  }

  if (
    [TPaymentMethodEnum.Pix, TPaymentMethodEnum.Boleto].includes(
      sale.paymentType
    )
  ) {
    pixels = pixels?.filter((p) => p.selectiveNonImmediatePurchase)
  }

  if (!pixels || pixels.length <= 0) {
    return null
  }

  const scripts = pixels.map((p) => ({
    script: TagFunctions.addScriptHeaderAsync(
      `${srcGoogleAdsPixelCheckout}${p.pixel}`
    ),
  }))

  const gtags = pixels.map((p) => `gtag('config','${p.pixel}');`).join("\n")
  const phoneConversionIds = pixels.map((p) => `${p.pixel}/${p.pixelToken}`)

  const script = TagFunctions.addScriptHeader(
    `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('set', {
        'phone_conversion_number': '${sale.customer.phone}',
        'phone_conversion_ids': ${JSON.stringify(phoneConversionIds)}
        });
    ${gtags}`
  )

  const scriptGtag = { script }

  const scriptsGtags = pixels.map((p) => ({
    script: TagFunctions.addScriptHeader(
      `gtag('event', 'conversion', {'send_to': '${p.pixel}/${p.pixelToken}',
        'value': ${sale.productValue ?? 0},
        'currency': 'BRL'
      });`
    ),
  }))

  return [...scripts, scriptGtag, ...scriptsGtags]
}

const initialDataLayer = (): ITagsProp => {
  const script = TagFunctions.addScriptHeader("dataLayer = [];")
  return { script }
}

const addDataLayerBeginCheckout = (
  productPrice?: IProductPrice
): ITagsProp | null => {
  if (
    !productPrice?.googleTagManagerId ||
    productPrice.googleTagManagerId.trim() === ""
  ) {
    return null
  }

  const script = TagFunctions.addScriptHeader(
    `dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: [{
            item_name: "${productPrice?.productName}", // Name or ID is required.
            item_id: "${productPrice?.codeId}",
            price: ${productPrice?.value},
            item_list_name: "${productPrice?.productName}",
            item_list_id: "${productPrice?.productId}",
            index: 1,
            quantity: 1
          }]
        }
      });`
  )

  return { script }
}

const addDataLayerPurchase = (sale: TSaleStorage): ITagsProp | null => {
  if (!sale.googleTagManagerId || sale.googleTagManagerId.trim() === "") {
    return null
  }

  const items = sale.items.map((i) => ({
    item_name: i.name,
    item_id: i.id,
    item_code: i.code,
    price: i.value,
    quantity: i.quantity,
  }))

  const script = TagFunctions.addScriptHeader(
    `dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: "${sale.orderCode}",
                affiliation: "${sale.affiliatedCode ?? ""}",
                value: "${sale.productValue ?? 0}",
                tax: "0.00",
                shipping: "0.00",
                currency: "BRL",
                coupon: "",
                items: ${JSON.stringify(items)}
            }
          });`
  )

  return { script }
}

const addGoogleTagManager = (
  googleTagManagerId: string | null | undefined
): ITagsProp | null => {
  if (!googleTagManagerId || googleTagManagerId.trim() === "") {
    return null
  }

  const script = TagFunctions.addScriptHeader(
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');`
  )

  const noscript = TagFunctions.addNoscriptBody(
    `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  )

  return { script, noscript }
}

const GoogleFunctions = {
  addTagGoogle,
  addTagGoogleAdsPixelCheckout,
  addTagGoogleAdsPixelPurchase,
  addGoogleTagManager,
  initialDataLayer,
  addDataLayerBeginCheckout,
  addDataLayerPurchase,
}

export default GoogleFunctions
