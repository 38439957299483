import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material"
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { TSale } from "../../../../core/types/Sale"
import TextFieldRequired from "../../../Inputs/TextFieldRequired"
import Validations from "../../../../core/functions/validations"
import { numberOnly } from "../../../../masks/masks"
import React from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { TPhoneCode } from "../../../../core/types/Phone"
import NumberFunctions from "../../../../core/functions/NumberFunctions"
import { useCheckoutContext } from "../../../../core/hooks/useCheckoutContext"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 130,
      borderRadius: "4px",
    },
  },
}

function getStyles(isSelected: boolean, theme: Theme) {
  return {
    fontWeight: !isSelected
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

export interface ICheckoutClient4 {
  control: Control<TSale, any>
  setValue: UseFormSetValue<TSale>
  watch: UseFormWatch<TSale>
  saveLostSale: () => void
  clearErrors: () => void
}

const CheckoutClient4 = ({
  control,
  setValue,
  watch,
  saveLostSale,
  clearErrors,
}: ICheckoutClient4) => {
  const theme = useTheme()
  const { productPrice } = useCheckoutContext()

  const [open, setOpen] = React.useState(false)
  const [phoneCode, setPhoneCode] = React.useState<TPhoneCode>({
    ...NumberFunctions.phoneCodeBR,
  })

  const emailValue = watch("email")
  const phoneValue = watch("phone")

  const customFields = productPrice?.customFields?.isCustomFields
    ? productPrice?.customFields?.fields.filter((field) => field.isChecked) ??
      []
    : []

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Stack direction="column" spacing={1.5}>
      <TextFieldRequired
        control={control}
        fieldName="name"
        label="Nome Completo"
        placeholder="Digite seu nome completo"
        onFieldExit={saveLostSale}
        clearErrors={clearErrors}
        inputProps={{
          "data-cy": "customerName",
          "data-testid": "customerName",
        }}
        dataCyErrorLabel="customerNameError"
      />
      <TextFieldRequired
        control={control}
        fieldName="email"
        label="Email"
        placeholder="Digite seu email para receber a compra"
        onFieldExit={saveLostSale}
        clearErrors={clearErrors}
        validate={(value) =>
          !value || value == "" ? true : Validations.email(value)
        }
        inputProps={{
          "data-cy": "customerEmail",
          "data-testid": "customerEmail",
          style: { textTransform: "lowercase" },
        }}
        dataCyErrorLabel="customerEmailError"
      />
      <TextFieldRequired
        control={control}
        fieldName="emailConfirm"
        label="Confirmar Email"
        placeholder="Confirme seu email digitado anteriormente"
        clearErrors={clearErrors}
        validate={(value) =>
          !value || value == ""
            ? true
            : Validations.email(value) &&
              ((value as string) ?? "").toLowerCase() ===
                emailValue.toLowerCase()
        }
        validateLabel={
          !Validations.email(watch("emailConfirm"))
            ? "Confirmação do E-mail inválido"
            : "E-mail não confere com a confirmação digitada"
        }
        inputProps={{
          "data-cy": "customerEmailConfirm",
          "data-testid": "customerEmailConfirm",
          style: { textTransform: "lowercase" },
        }}
        dataCyErrorLabel="customerEmailConfirmError"
      />

      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
        <TextFieldRequired
          control={control}
          fieldName="phone"
          label="Celular"
          placeholder="(99) 99999-9999"
          shrink={phoneValue !== ""}
          inputProps={{
            inputMode: "numeric",
            maxLength: 15,
            "data-cy": "customerPhone",
            "data-testid": "customerPhone",
          }}
          sxInputLabel={{
            ml: phoneValue === "" ? 13.5 : 3.5,
          }}
          dataCyErrorLabel="customerPhoneError"
          onFieldExit={saveLostSale}
          clearErrors={clearErrors}
          validate={(value) =>
            !value || value == "" ? true : numberOnly(value).length === 11
          }
          startAdornment={
            <>
              <Select
                labelId="select-phone-code-label"
                id="select-phone-code"
                variant="standard"
                size="small"
                autoWidth={false}
                disableUnderline={true}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={phoneCode.code}
                onChange={(event) => {
                  const c = NumberFunctions.phoneCodes.find(
                    (cv) => cv.code === event.target.value
                  )
                  if (c) {
                    setPhoneCode(c)
                  }
                }}
                renderValue={(selected) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <Avatar
                        alt={phoneCode.countryName}
                        src={phoneCode.flag}
                        sx={{ width: 24, height: 24 }}
                      />
                      <Typography>{selected}</Typography>
                    </Stack>
                  )
                }}
                MenuProps={MenuProps}
                inputProps={{ sx: { padding: "0 !important" } }}
                IconComponent={() => (
                  <IconButton
                    size="small"
                    onClick={() => setOpen(!open)}
                    sx={{ width: 26, height: 26, ml: 1 }}
                  >
                    {open ? (
                      <KeyboardArrowUpIcon color="disabled" />
                    ) : (
                      <KeyboardArrowDownIcon color="disabled" />
                    )}
                  </IconButton>
                )}
                style={{ borderRadius: "4px", paddingRight: "0px" }}
              >
                {NumberFunctions.phoneCodes.map((phone) => (
                  <MenuItem
                    key={phone.code}
                    value={phone.code}
                    style={getStyles(phone.code === phoneCode.code, theme)}
                  >
                    <Stack direction="row" spacing={2}>
                      <Avatar
                        alt={phone.countryName}
                        src={phone.flag}
                        sx={{ width: 24, height: 24 }}
                      />
                      <Typography fontWeight={500}>{phone.code}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  backgroundColor: "#DCDDDE",
                  height: "22.5px",
                  width: "2px",
                  ml: 1,
                  mr: 1,
                }}
              />
            </>
          }
        />

        <Box sx={{ display: { xs: "block", sm: "none" } }} />

        <TextFieldRequired
          control={control}
          fieldName="document"
          label="CPF/CNPJ"
          placeholder="999.999.999-99"
          inputProps={{
            inputMode: "numeric",
            maxLength: 18,
            "data-cy": "customerDocument",
            "data-testid": "customerDocument",
          }}
          dataCyErrorLabel="customerDocumentError"
          clearErrors={clearErrors}
          validate={(value) =>
            !value || value == "" ? true : Validations.CPF_CNPJ(value)
          }
        />
      </Stack>

      {customFields.map((field, index) => (
        <TextFieldRequired
          key={field.identity}
          control={control}
          fieldName={`customFields.${index}.value`}
          label={field.name}
          placeholder=""
          inputProps={{
            "data-cy": field.identity,
            "data-testid": field.identity,
          }}
          dataCyErrorLabel={field.identity}
          clearErrors={clearErrors}
          validate={(value) => (value ?? "") !== ""}
          onChageValue={(value) => {
            setValue(`customFields.${index}`, {
              identity: field.identity,
              name: field.name,
              value,
            })
          }}
        />
      ))}
    </Stack>
  )
}

export default CheckoutClient4
