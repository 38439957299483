import {
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  Paper,
  Radio,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { IOrderBump } from "../../../../../core/types/OrderBump"
import numbers from "../../../../../core/functions/NumberFunctions"
import React from "react"
import { TPaymentMethodEnum } from "../../../../../core/types/Product"

export interface ICheckoutOrderBump3Prop {
  paymentInstallment: number
  paymentMethodType: number
  orderBump: IOrderBump
  onChange(orderBump: IOrderBump): void
}

const CheckoutOrderBump4 = ({
  paymentInstallment,
  paymentMethodType,
  orderBump,
  onChange,
}: ICheckoutOrderBump3Prop): JSX.Element => {
  const isInstallments =
    [
      TPaymentMethodEnum.CreditCard,
      TPaymentMethodEnum.TwoCreditCard,
      TPaymentMethodEnum.CreditCardAndPix,
    ].includes(paymentMethodType) &&
    orderBump.installments &&
    orderBump.installments.length > 0 &&
    paymentInstallment > 1

  const value =
    orderBump.productPrice?.firstPurchasePrice ?? orderBump.productPrice?.value
  const installmentValue = isInstallments
    ? orderBump.installments?.find(
        (o) => o.installmentNumber === paymentInstallment
      )?.value
    : null
  const orderBumpValue = installmentValue ?? value

  const handleClick = () => {
    orderBump.selected = !orderBump.selected
    onChange(orderBump)
  }

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  )

  return (
    <>
      <ListItem key={`ob-${orderBump.id}`} disablePadding>
        <Stack
          direction={"column"}
          width="100%"
          sx={{
            mb: 2,
            backgroundColor: orderBump.selected ? "#F2F2FF" : "white",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(236, 80, 64, 1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              borderRadius: "8px 8px 0px 0px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            padding={1}
          >
            <Typography
              variant="h5"
              fontWeight={700}
              fontFamily={"Montserrat"}
              fontSize={"14px"}
              lineHeight={"32px"}
              color={"rgba(243, 243, 244, 1)"}
            >
              {orderBump.title}
            </Typography>
          </Box>

          <Stack
            sx={{
              border: "2px dashed rgba(236, 80, 64, 1)",
              borderTop: "0px",
              backgroundColor: "rgba(251, 243, 226, 1)",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                fontFamily={"Montserrat"}
                fontSize={"12px"}
                lineHeight={"20px"}
                fontWeight={400}
                color={"rgba(56, 57, 59, 1)"}
                p={1}
              >
                {orderBump.description}
              </Typography>
            </Box>

            <Box p={1}>
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  gap: "8px",
                  borderRadius: "8px",
                }}
              >
                <ListItemButton
                  dense
                  role={undefined}
                  className="listItemButtonOrderBump"
                  onClick={handleClick}
                >
                  <Box width="100%" sx={{ flexGrow: 1, display: "flex" }}>
                    <Box width="100%">
                      <Stack
                        direction={"row"}
                        sx={{ width: "100%" }}
                        alignItems="center"
                        gap="12px"
                      >
                        <Box>
                          <Stack direction="row" alignItems="center" gap="6px">
                            <img
                              alt="vector"
                              src="/assets/icons/common/vector.svg"
                              style={{
                                height: isSmallScreen ? 24 : 35,
                                width: isSmallScreen ? 24 : 35,
                              }}
                            />
                            <Box>
                              <Checkbox checked={orderBump.selected ?? false} />
                            </Box>
                            <Box
                              sx={{
                                borderRadius: "8px",
                              }}
                            >
                              {orderBump.productPrice?.product?.coverUrl &&
                                orderBump.productPrice?.product?.coverUrl !==
                                  "" && (
                                  <img
                                    src={
                                      !orderBump.productPrice?.product?.coverUrl
                                        ? `${process.env.PUBLIC_URL}/top.png`
                                        : orderBump.productPrice.product
                                            .coverUrl
                                    }
                                    alt={orderBump.productPrice?.product?.name}
                                    style={{
                                      height: isSmallScreen ? 24 : 80,
                                      width: isSmallScreen ? 24 : 80,
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                  />
                                )}
                            </Box>
                          </Stack>
                        </Box>
                        <Stack
                          direction={"column"}
                          spacing={1}
                          sx={{ flexGrow: 1, width: "100%" }}
                        >
                          {isInstallments &&
                            (orderBump.anchoringPrice === null ? (
                              <Typography
                                variant="body1"
                                color="#85868A"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ flexGrow: 1 }}
                              >
                                {orderBump.descriptionPrice}{" "}
                                <span
                                  style={{
                                    color: "rgba(46, 178, 66, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(orderBumpValue)}
                                  </b>
                                </span>{" "}
                                por parcelas
                              </Typography>
                            ) : (
                              <Typography
                                variant="body1"
                                color="#85868A"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ flexGrow: 1 }}
                              >
                                Sim, desejo adicionar{" "}
                                <b>{orderBump.productPrice?.product?.name}</b>{" "}
                                de {""}
                                <span
                                  style={{
                                    color: "rgba(236, 80, 64, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(
                                      orderBump.anchoringPrice
                                    )}
                                  </b>
                                </span>{" "}
                                por apenas mais{" "}
                                <span
                                  style={{
                                    color: "rgba(46, 178, 66, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(orderBumpValue)}
                                  </b>
                                </span>{" "}
                                na parcela.
                              </Typography>
                            ))}

                          {!isInstallments &&
                            (orderBump.anchoringPrice === null ? (
                              <Typography
                                variant="body1"
                                color="#85868A"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ flexGrow: 1 }}
                              >
                                {orderBump.descriptionPrice}{" "}
                                <span
                                  style={{
                                    color: "rgba(46, 178, 66, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(orderBumpValue)}
                                  </b>
                                </span>
                              </Typography>
                            ) : (
                              <Typography
                                variant="body1"
                                color="#85868A"
                                fontSize="14px"
                                lineHeight="18px"
                                sx={{ flexGrow: 1 }}
                              >
                                Sim, desejo adicionar{" "}
                                <b>{orderBump.productPrice?.product?.name}</b>{" "}
                                de {""}
                                <span
                                  style={{
                                    color: "rgba(236, 80, 64, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(
                                      orderBump.anchoringPrice
                                    )}
                                  </b>
                                </span>{" "}
                                por apenas mais{" "}
                                <span
                                  style={{
                                    color: "rgba(46, 178, 66, 1)",
                                    fontFamily: "Montserrat",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                  }}
                                >
                                  <b>
                                    {numbers.formatMoneyDefault(orderBumpValue)}
                                    .
                                  </b>
                                </span>{" "}
                              </Typography>
                            ))}
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </ListItemButton>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </ListItem>
    </>
  )
}

export default CheckoutOrderBump4
