import { Box, Stack } from "@mui/material"
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
} from "../../core/types/CustomCheckout"
import CheckoutComponenetWidget from "./CheckoutComponentWidget"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../core/types/Product"
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { TSale, TSaleProduct } from "../../core/types/Sale"
import { IOrderBump } from "../../core/types/OrderBump"

export interface ICheckoutComponenetListProps {
  list: TCustomCheckoutComponentItem[]
  id: React.MutableRefObject<string>
  loading: boolean
  loadingVoucher: boolean
  isCheckoutBuilder: boolean
  searchedZipCode: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  productInstallments?: IProductPricePaymentInstallment[]
  installments?: IProductPricePaymentInstallment[]
  orderBumps?: IOrderBump[]
  products: TSaleProduct[]
  paymentInstallment: number
  paymentMethodType: number
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>
  setOpenTermsPurchase: (value: boolean) => void
  onSendPixel: () => void
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

/**
 * Componente responsável por gerenciar os componentes do checkout, incluindo os componentes do Checkout Builder.
 */
const CheckoutComponenetList = ({
  list,
  id,
  loading,
  loadingVoucher,
  isCheckoutBuilder,
  searchedZipCode,
  data,
  control,
  errors,
  productInstallments,
  installments,
  orderBumps,
  products,
  paymentInstallment,
  paymentMethodType,
  paymentTotal,
  paymentValue,
  watch,
  setValue,
  onSubmit,
  onKeyDown,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresComplementKeyDown,
  handleAddresNumberKeyDown,
  orderBumpChange,
  setOpenTermsPurchase,
  onSendPixel,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutComponenetListProps) => {
  const listFiltered = list.filter(
    (l) => l.type !== TCustomCheckoutComponentType.none
  )

  const listFilteredSorted = listFiltered.sort(
    (a, b) => (a.order ?? 0) - (b.order ?? 0)
  )

  return (
    <Box>
      <Stack direction="column" spacing={0.5}>
        {listFilteredSorted.map((item, index) => (
          <CheckoutComponenetWidget
            key={`CheckoutComponenetWidget${index}`}
            item={item}
            id={id}
            loading={loading}
            loadingVoucher={loadingVoucher}
            isCheckoutBuilder={isCheckoutBuilder}
            searchedZipCode={searchedZipCode}
            data={data}
            control={control}
            errors={errors}
            productInstallments={productInstallments}
            installments={installments}
            orderBumps={orderBumps}
            products={products}
            paymentInstallment={paymentInstallment}
            paymentMethodType={paymentMethodType}
            paymentTotal={paymentTotal}
            paymentValue={paymentValue}
            watch={watch}
            setValue={setValue}
            onSubmit={onSubmit}
            onKeyDown={onKeyDown}
            saveLostSale={saveLostSale}
            clearErrors={clearErrors}
            searchZipCode={searchZipCode}
            handleAddresComplementKeyDown={handleAddresComplementKeyDown}
            handleAddresNumberKeyDown={handleAddresNumberKeyDown}
            orderBumpChange={orderBumpChange}
            setOpenTermsPurchase={setOpenTermsPurchase}
            onSendPixel={onSendPixel}
            onApplyVoucher={onApplyVoucher}
            onRemoveVoucher={onRemoveVoucher}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default CheckoutComponenetList
