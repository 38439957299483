import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material"
import React from "react"
import { TPaymentMethodEnum } from "../../core/types/Product"

interface CheckoutSendingBackdropProps {
  open: boolean
  paymentMethod: number
}

const CheckoutSendingBackdrop = ({
  open,
  paymentMethod,
}: CheckoutSendingBackdropProps) => {
  return (
    <Backdrop
      sx={{
        bgColor: "#3333",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <Stack
        direction={"column"}
        width={"100%"}
        sx={{ p: 3 }}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <img
          src="/assets/celetusLogo/white.svg"
          alt="celetus"
          style={{
            width: "124px",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
        <Stack direction="column" alignItems="center">
          {[
            TPaymentMethodEnum.CreditCard,
            TPaymentMethodEnum.TwoCreditCard,
            TPaymentMethodEnum.CreditCardAndPix,
          ].includes(paymentMethod) && (
            <Typography
              variant="body1"
              fontWeight={500}
              textAlign="center"
              data-cy="processingPayment"
              data-testid="processingPayment"
            >
              Estamos processando seu pagamento.
            </Typography>
          )}
          <Typography variant="body1" fontWeight={500} textAlign="center">
            Aguarde, isso pode levar alguns segundos.
          </Typography>
        </Stack>
        <CircularProgress size="2rem" />
      </Stack>
    </Backdrop>
  )
}

export default CheckoutSendingBackdrop
