import {
  paymentBoletoItem,
  paymentCreditCardItem,
  paymentDebitCardItem,
  paymentPixItem,
} from "../../../components/Checkout/Layouts/Layout4/Payment/PaymentConsts"
import { PaymentData } from "../../../core/types/Order"
import {
  IProductPricePayment,
  TPaymentMethodEnum,
} from "../../../core/types/Product"

const isPaymentValid = (
  payments: PaymentData[],
  payment: IProductPricePayment,
  paymentMethodType: TPaymentMethodEnum
) => {
  let valid = payment.methodType === paymentMethodType

  payments.map((pay) => {
    if (valid) {
      if (pay.paymentMethod === TPaymentMethodEnum.CreditCard) {
        valid =
          pay.creditCardData?.name !== "" &&
          pay.creditCardData?.number !== "" &&
          pay.creditCardData?.month !== undefined &&
          pay.creditCardData?.cvv !== ""
      }

      if (pay.paymentMethod === TPaymentMethodEnum.DebitCard) {
        valid =
          pay.debitCardData?.name !== "" &&
          pay.debitCardData?.number !== "" &&
          pay.debitCardData?.month !== undefined &&
          pay.debitCardData?.year !== undefined &&
          pay.debitCardData?.cvv !== ""
      }
    }
  })

  return valid
}

const getPaymentList = (
  paymentMethod: TPaymentMethodEnum,
  saleValue: number
) => {
  if (paymentMethod === TPaymentMethodEnum.CreditCard) {
    return [{ ...paymentCreditCardItem, value: saleValue }]
  }
  if (paymentMethod === TPaymentMethodEnum.DebitCard) {
    return [{ ...paymentDebitCardItem, value: saleValue }]
  }
  if (paymentMethod === TPaymentMethodEnum.Pix) {
    return [{ ...paymentPixItem, value: saleValue }]
  }
  if (paymentMethod === TPaymentMethodEnum.Boleto) {
    return [{ ...paymentBoletoItem, value: saleValue }]
  }
  if (paymentMethod === TPaymentMethodEnum.TwoCreditCard) {
    const value = saleValue / 2
    return [
      { ...paymentCreditCardItem, value },
      { ...paymentCreditCardItem, value },
    ]
  }
  if (paymentMethod === TPaymentMethodEnum.CreditCardAndPix) {
    const value = saleValue / 2
    return [
      { ...paymentCreditCardItem, value },
      { ...paymentPixItem, value },
    ]
  }
  return []
}

const PaymentFunctions = {
  /**
   * Verifica se a forma de pagamento em questão está com os campos preenchidos de maneira válida
   *
   * @param payments Array com os valores de cada pagamento
   * @param payment Forma de pagamento com os valores de parcelamento
   * @param paymentMethodType Forma de pagamento selecionada
   * @returns Retorna `true` se os campos for válidos, `false` caso contrário.
   */
  isPaymentValid,
  /**
   * Retorna a lista de pagamentos conforme a forma de pagamento selecionado, para ser preenchido os valores para pagamento
   *
   * @param paymentMethod Forma de pagamento selecionada
   * @param saleValue Valor para destrinchar nas formas de pagamento
   * @returns Retorna a lista de pagamento para a forma de pagamento desejado.
   */
  getPaymentList,
}

export default PaymentFunctions
