import PaymentsIcon from "@mui/icons-material/PaymentsOutlined"
import DebitCardIcon from "@mui/icons-material/CreditCardTwoTone"
import PixIcon from "@mui/icons-material/PixRounded"
import { TPaymentMethodEnum } from "../../../../../core/types/Product"
import { PaymentData } from "../../../../../core/types/Order"

export const checkoutPayment4Style = {
  [TPaymentMethodEnum.CreditCard]: {
    icon: {
      [0]: <img src="/assets/icons/payments/credit-card.svg" alt="Crédito" />,
      [1]: (
        <img
          src="/assets/icons/payments/credit-card-selected.svg"
          alt="Crédito"
        />
      ),
    },
    label: "Crédito",
  },
  [TPaymentMethodEnum.DebitCard]: {
    icon: {
      [0]: <DebitCardIcon sx={{ color: "#323232" }} />,
      [1]: <DebitCardIcon sx={{ color: "#1C52BD" }} />,
    },
    label: "Débito",
  },
  [TPaymentMethodEnum.Pix]: {
    icon: {
      [0]: <img src="/assets/icons/payments/pix.svg" alt="PIX" />,
      [1]: <img src="/assets/icons/payments/pix-selected.svg" alt="PIX" />,
    },
    label: "PIX",
  },
  [TPaymentMethodEnum.Boleto]: {
    icon: {
      [0]: <img src="/assets/icons/payments/barcode.svg" alt="Code" />,
      [1]: <img src="/assets/icons/payments/barcode-selected.svg" alt="Code" />,
    },
    label: "Boleto",
  },
  [TPaymentMethodEnum.BankTransfer]: {
    icon: {
      [0]: <PixIcon sx={{ color: "#323232" }} />,
      [1]: <PixIcon sx={{ color: "#1C52BD" }} />,
    },
    label: "Transferência",
  },
  [TPaymentMethodEnum.TwoCreditCard]: {
    icon: {
      [0]: <PaymentsIcon sx={{ color: "#323232" }} />,
      [1]: <PaymentsIcon sx={{ color: "#1C52BD" }} />,
    },
    label: "2 Cartões",
  },
  [TPaymentMethodEnum.CreditCardAndPix]: {
    icon: {
      [0]: <PixIcon sx={{ color: "#323232" }} />,
      [1]: <PixIcon sx={{ color: "#1C52BD" }} />,
    },
    label: "Crédito e PIX",
  },
}

export const paymentCreditCardItem: PaymentData = {
  paymentMethod: TPaymentMethodEnum.CreditCard,
  discount: 0,
  subtotal: 0,
  total: 0,
  voucherStatus: "none",
  creditCardData: {
    name: "",
    cvv: "",
    number: "",
    installments: 12,
    month: 0,
    year: 0,
    validate: "",
  },
}

export const paymentDebitCardItem: PaymentData = {
  paymentMethod: TPaymentMethodEnum.DebitCard,
  discount: 0,
  subtotal: 0,
  total: 0,
  voucherStatus: "none",
  debitCardData: {
    name: "",
    cvv: "",
    number: "",
    month: 0,
    year: 0,
  },
}

export const paymentPixItem: PaymentData = {
  paymentMethod: TPaymentMethodEnum.Pix,
  discount: 0,
  subtotal: 0,
  total: 0,
  voucherStatus: "none",
}

export const paymentBoletoItem: PaymentData = {
  paymentMethod: TPaymentMethodEnum.Boleto,
  discount: 0,
  subtotal: 0,
  total: 0,
  voucherStatus: "none",
}
