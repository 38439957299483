import { Grid, Typography } from "@mui/material"
import {
  IProductPricePaymentInstallment,
  TPaymentMethodEnum,
  TProductFrequency,
  TProductTypePayment,
} from "../../../core/types/Product"
import numbers from "../../../core/functions/NumberFunctions"
import Convertions from "../../../core/functions/convertions"
import React from "react"

export interface IOrderProductProp {
  paymentMethodType: number
  installmentNumber: number
  description: string
  value: number
  defaultValue: number
  installments?: IProductPricePaymentInstallment[] | null
  subtitle?: boolean
  typePayment?: TProductTypePayment
  frequency?: TProductFrequency
}

const OrderItem = ({
  paymentMethodType,
  installmentNumber,
  description,
  value,
  installments,
  subtitle,
  defaultValue,
  typePayment,
  frequency,
}: IOrderProductProp) => {
  const isSignature = typePayment === TProductTypePayment.RecurringSubscription

  if (paymentMethodType === TPaymentMethodEnum.CreditCard) {
    var installmentValue = defaultValue

    if (installments && installments !== null) {
      const installment = installments.find(
        (i) => i.installmentNumber === installmentNumber
      )
      if (installment) {
        installmentValue = installment.value
      }
    }

    var complementValue = ""
    const frequencyString = isSignature
      ? Convertions.productFrequencyToType(frequency)
      : ""

    if (frequencyString !== "") {
      complementValue = ` */ ${frequencyString}`
    }

    return (
      <Grid container>
        <Grid item xs={7}>
          <Typography
            variant={subtitle || false ? "subtitle1" : "body2"}
            fontWeight={subtitle || false ? 500 : undefined}
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant={
              subtitle || false ? "subtitle1" : isSignature ? "body2" : "body1"
            }
            fontWeight={subtitle || false ? 500 : 400}
            textAlign="right"
          >
            {`${installmentNumber}x de ${numbers.formatMoneyDefault(
              installmentValue
            )}${complementValue}`}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={7}>
        <Typography
          variant={subtitle || false ? "subtitle1" : "body2"}
          fontWeight={subtitle || false ? 500 : undefined}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography
          variant={subtitle || false ? "subtitle1" : "body1"}
          fontWeight={subtitle || false ? 500 : 400}
          textAlign="right"
        >
          {numbers.formatMoneyDefault(value)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default OrderItem
