import { useEffect, useRef } from "react"
import { CustomCheckoutBlock } from "../../core/types/CustomCheckout"
import CustomCheckoutFunctions from "../../core/functions/CustomCheckoutFunctions"

export interface ICheckoutTextEditorProps {
  initialContent: string
}

function CheckoutTextView({ initialContent }: ICheckoutTextEditorProps) {
  var ref = useRef<HTMLDivElement>(null)

  const getInitialContent = (): CustomCheckoutBlock[] => {
    try {
      return JSON.parse(initialContent) as CustomCheckoutBlock[]
    } catch (e) {
      return []
    }
  }

  useEffect(() => {
    if (ref.current) {
      const content = getInitialContent()
      const html = CustomCheckoutFunctions.jsonToHtml(content)
      ref.current.innerHTML = html
    }
  }, [initialContent])

  return <div ref={ref} style={{ padding: "0px 16px" }}></div>
}

export default CheckoutTextView
