import { Stack } from "@mui/material"
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useEffect, useState } from "react"
import React from "react"
import { TSale } from "../../../../../core/types/Sale"
import { creditsCards } from "../../../../../core/constants"
import { CreditCard } from "../../../../../core/types/CreditCard"
import TextFieldRequired from "../../../../Inputs/TextFieldRequired"

export interface ICreditCardPaymentPrice5Props {
  visible: boolean
  control: Control<TSale, any>
  index: number
  watch: UseFormWatch<TSale>
  clearErrors: UseFormClearErrors<TSale>
}

const DebitCardPaymentPrice5 = (props: ICreditCardPaymentPrice5Props) => {
  const { visible, control, index, watch, clearErrors } = props

  const [creditCard, setCreditCard] = useState<CreditCard | undefined>()

  const paymentCardNumber = watch(
    `payment.payments.${index}.debitCardData.number`
  )
  const paymentCardCVV = watch(`payment.payments.${index}.debitCardData.cvv`)

  useEffect(() => {
    var exists = false
    creditsCards.map((cc) => {
      if (cc.compare(paymentCardNumber)) {
        setCreditCard(cc)
        exists = true
      }
      return true
    })
    if (!exists) {
      setCreditCard(undefined)
    }
  }, [paymentCardNumber])

  useEffect(() => {
    if ((paymentCardCVV || "").length === 4) {
      document.getElementById("buttonCommit")?.focus()
    }
  }, [paymentCardCVV])

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ display: visible ? "block" : "none" }}
    >
      <TextFieldRequired
        control={control}
        fieldName="payment.cardName"
        label="Nome impresso no cartão"
        placeholder="Digite o nome impresso no cartão"
        clearErrors={clearErrors}
        required={visible}
      />
      <TextFieldRequired
        control={control}
        fieldName="payment.cardNumber"
        label="Número do cartão"
        placeholder="Digite somente números"
        inputProps={{ inputMode: "numeric", maxLength: 19 }}
        clearErrors={clearErrors}
        required={visible}
        endAdornment={
          creditCard && (
            <img
              src={creditCard.getImageUrl()}
              alt="credit card"
              style={{ height: 30, objectFit: "contain" }}
            />
          )
        }
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <TextFieldRequired
          control={control}
          fieldName="payment.cardValidate"
          label="Validade do cartão (MM/AA)"
          placeholder="MM/AA"
          inputProps={{ inputMode: "numeric", maxLength: 5 }}
          clearErrors={clearErrors}
          required={visible}
        />
        <TextFieldRequired
          control={control}
          fieldName="payment.cardCCV"
          label="Cód. de segurança (CVV)"
          placeholder="999"
          inputProps={{
            inputMode: "numeric",
            maxLength: 4,
          }}
          clearErrors={clearErrors}
          required={visible}
        />
      </Stack>
    </Stack>
  )
}

export default DebitCardPaymentPrice5
